import styled from "styled-components";
import { colors } from "../../styles/theme";

export const CustomDropdown = styled.div`
  position: relative;
  width: 100%;
  select::-ms-expand {
    display: none;
  }
  .caret {
    position: absolute;
    top: 45%;
    right: 1.5rem;
    width: 1rem;
    pointer-events: none;
  }
  select {
    width: 100%;
    border: 2px solid ${colors.grey};
    display: inline-block;
    box-sizing: border-box;
    padding: 0.25rem 2rem 0.25rem 1.5rem;
    font: inherit;
    font-size: 1.375rem;
    line-height: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    appearance: none;
    background-repeat: no-repeat;
    /* background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
      linear-gradient(135deg, currentColor 50%, transparent 50%);
    background-position: right 15px top 1em, right 10px top 1em;
    background-size: 5px 5px, 5px 5px; */
    /* background: #020106
      url(${require("../../../assets/icons/downCaret.svg").default}) no-repeat
      calc(100% - 10px) 50%;
    background-size: 15px; */
    background-color: #020106;
    border: 2px solid #3c3b3e;
    outline: none;
    color: ${colors.white};
    /* font-weight: 600; */
    border-radius: 8px;
    height: 3.5rem;
    cursor: pointer;
    select:hover {
      background-color: ${colors.primaryBlueDark};
      border: 2px solid #3c3b3e;
    }
    select:active {
      border: 2px solid ${colors.lightGrey};
      border: 2px solid #3c3b3e;
    }
    option {
      font-size: 16px;
    }
  }
`;

import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import React, { useEffect, FC } from "react";
import { Button } from "../../../shared/components/button";
import useAuth from "../../../walletHelpers/useAuth";
import { Container, LoginContainer } from "./styles";
import { Wallet } from "../../../shared/images";

interface LoginProps {
  setIsUserLoggedIn: (isLoggedIn: boolean) => void;
}

const Login: FC<LoginProps> = ({ setIsUserLoggedIn }) => {
  const { ethereum }: any = window;
  const { login } = useAuth();
  const { account } = useWeb3React<Web3Provider>();

  const connectWallet = async () => {
    try {
      await login(1);
      localStorage.setItem("connectorId", JSON.stringify(1));
      if (account) {
        setIsUserLoggedIn(true);
      }
    } catch (error) {
      console.error("Failed to connect wallet:", error);
    }
  };

  useEffect(() => {
    if (account) {
      setIsUserLoggedIn(true);
    }
  }, [account, setIsUserLoggedIn]);

  const openLinkInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  const renderButton = () => {
    if (ethereum) {
      return (
        <Button onClick={connectWallet}>
          <div className="connectBtnBody">
            <img src={Wallet} alt="Wallet icon" />
            <span>CONNECT WALLET</span>
          </div>
        </Button>
      );
    } else {
      return (
        <Button onClick={() => openLinkInNewTab("https://metamask.io/")}>
          <div className="connectBtnBody">
            <img src={Wallet} alt="Wallet icon" />
            <span>Install Metamask</span>
          </div>
        </Button>
      );
    }
  };

  return (
    <LoginContainer>
      <Container>
        <div className="darkBg">
          <div className="centered">
            <h1>Welcome to Orasis</h1>
            <p>{ethereum ? "Please connect with wallet" : "Please install metamask wallet"}</p>
            {renderButton()}
          </div>
        </div>
      </Container>
    </LoginContainer>
  );
};

export default Login;

import { Web3ReactProvider } from "@web3-react/core";
import React from "react";
import "react-app-polyfill/ie11";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./modules/app/App";
import * as serviceWorker from "./serviceWorker";
import getLibrary from "./walletHelpers/getLibrary";

ReactDOM.render(
  <Web3ReactProvider getLibrary={getLibrary}>
    <App />
  </Web3ReactProvider>,
  document.getElementById("root")
);
serviceWorker.unregister();

export enum Paths {
  root = "/",
  dashboard = "/dashboard",
  login = "/login",
  admin = "/admin",
  adminLogin = "/admin/login",
  adminDashboard = "/admin/dashboard",
  adminManage = "/admin/manage",

  userLogin = "/user/login",
  userDashboard = "/user/dashboard",
}

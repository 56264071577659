import { useQuery } from "@apollo/client";
import { useWeb3React } from "@web3-react/core";
import { useSnackbar } from "notistack";
import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  claimTokens,
  getIsVestingStarted,
  getTeamHash,
  getUserClaimableTokens,
  getUserClaimedTokens,
} from "../../../../logic/contracts/tokenContractService";
import { vestingClient } from "../../../../logic/graphql/client";
import { GET_USER_STATUS } from "../../../../logic/graphql/userData";
import UserService from "../../../../logic/services/user.service";
import { Button } from "../../../../shared/components/button";
import {
  CardContainer,
  Container,
  TabContainer,
  TabItem,
  TokenBalance,
  TokenDetailsWrapper,
  TokenIcon,
  TokenIconContainer,
  TokenItem,
  WarningText,
} from "./style";
import { formatNumber, weiToEth } from "../../../../utils/helpers";
import BigNumber from "bignumber.js";
import { chain_id } from "../../../../config";
import { Aave } from "../../../../shared/images";
import { LoaderContext } from "../../../app/components/loader/LoaderProvider";

function TokenDetails(props: any) {
  const { selectedCategory, totalAllocatedAmount, userData } = props;
  const { account, chainId } = useWeb3React();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [claimableAmount, setClaimableAmount] = useState<string>();
  const [claimableInfo, setClaimableInfo] = useState<any>(null);
  const [claimedAmount, setClaimedAmount] = useState<string>();
  const [isVestingStarted, setIsVestingStarted] = useState<null | boolean>(
    null
  );
  const [activeTab, setActiveTab] = useState("CLAIMED");
  const [userStatus, setUserStatus] = useState<any>(null);

  const [teamHash, setTeamHash] = useState(null);

  const { setLoading } = useContext(LoaderContext);

  const { data: userStatusData, refetch } = useQuery(GET_USER_STATUS, {
    client: vestingClient,
    variables: {
      where: {
        address: account?.toLowerCase(),
        team: teamHash,
      },
    },
    skip: !teamHash,
  });

  const getTeamHashByTeamName = useCallback(async () => {
    const team = await getTeamHash(selectedCategory);
    setTeamHash(team);
  }, [selectedCategory]);

  useEffect(() => {
    if (selectedCategory && chainId === chain_id) {
      // if (selectedCategory && chainId === 80001) {
      getTeamHashByTeamName();
    }
  }, [selectedCategory, chainId, getTeamHashByTeamName]);

  useEffect(() => {
    if (userStatusData) {
      setUserStatus(userStatusData?.users?.[0]);
    }
  }, [userStatusData]);

  const handleTabChange = (tabName: string) => {
    setActiveTab(tabName);
  };

  const getIsUserClaimable = useCallback(() => {
    console.log("jhklg", claimableAmount);

    return (
      claimableAmount &&
      !new BigNumber(claimableAmount)?.isZero() &&
      isVestingStarted
    );
    // return parseInt(claimableAmount) === 0 || !isVestingStarted;
  }, [claimableAmount, isVestingStarted]);

  const handleClaim = async () => {
    try {
      if (!getIsUserClaimable()) {
        return;
      }
      setLoading(true);
      enqueueSnackbar("Claiming tokens...", {
        variant: "info",
        persist: true,
      });
      if (account && selectedCategory) {
        const encodedSelectedCategory = encodeURIComponent(selectedCategory);
        // const merkleProof = await UserService.getUserMerkleProof(
        //   account,
        //   encodedSelectedCategory
        // );
        await claimTokens(
          selectedCategory,
          claimableInfo?.hexProof?.merkleProof,
          claimableInfo?.signature,
          claimableInfo?.totalTokensAllocated,
          claimableInfo?.currentClaimableTokens,
          claimableInfo?.salt
        );
        // await claimTokens(
        //   selectedCategory,
        //   merkleProof,
        //   totalAllocatedAmount,
        //   account
        // );
        closeSnackbar();
        enqueueSnackbar("Claim successfully!", {
          variant: "success",
          persist: false,
        });
        getUserClaimableTokens(
          selectedCategory,
          account,
          userData?.totalTokensAllocated
        ).then((res) => {
          setClaimableInfo(res);
          setClaimableAmount(
            new BigNumber(res?.currentClaimableTokens)?.toString()
          );
          // setClaimableAmount(res?.toString());
        });
        getUserClaimedTokens(selectedCategory, account).then((res) => {
          setClaimedAmount(res?.toString());
        });
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      closeSnackbar();
      setLoading(false);
      enqueueSnackbar("Claim failed. Please try again.", {
        variant: "error",
        persist: false,
        hideIconVariant: true,
      });
    }
  };

  useEffect(() => {
    try {
      if (
        new BigNumber(userData?.totalTokensAllocated)?.isGreaterThanOrEqualTo(
          new BigNumber(0)
        ) &&
        // userData?.totalTokensAllocated >= 0 &&
        account &&
        chainId === chain_id
      ) {
        // if (userData?.totalTokensAllocated >= 0 && account && chainId === 80001) {
        getUserClaimableTokens(
          selectedCategory,
          account,
          userData?.totalTokensAllocated
        ).then((res: any) => {
          setClaimableInfo(res);
          setClaimableAmount(
            new BigNumber(res?.currentClaimableTokens)?.toString()
          );
          // setClaimableAmount(res?.currentClaimableTokens);
          // setClaimableAmount(res?.toString());
        });
        getUserClaimedTokens(selectedCategory, account).then((res) => {
          setClaimedAmount(res?.toString());
        });
        // getIsVestingStarted(selectedCategory).then((res) => {
        //   setIsVestingStarted(res === 1);
        // });
        setIsVestingStarted(
          userData?.vestingStartTimestamp * 1000 <= Date.now()
        );
        refetch();
      }
    } catch (error) {
      console.error("****** Error ********* ", error);
    }
  }, [userData, account, selectedCategory, chainId, activeTab, refetch]);

  return (
    <Container>
      <TabContainer>
        <TabItem
          isActive={activeTab === "CLAIMED"}
          onClick={() => {
            handleTabChange("CLAIMED");
          }}
        >
          CLAIMED
        </TabItem>
        <TabItem
          isActive={activeTab === "CLAIMABLE"}
          onClick={() => {
            handleTabChange("CLAIMABLE");
          }}
        >
          CLAIMABLE
        </TabItem>
      </TabContainer>
      <CardContainer>
        {activeTab === "CLAIMED" && (
          <TokenDetailsWrapper>
            <div>
              <div className="title">
                <span>Claimed</span>
                <TokenIcon src={Aave} title="Orasis Token" />
                <span>Tokens :</span>
              </div>
              <div className="value">
                {claimedAmount &&
                new BigNumber(claimedAmount)?.isGreaterThanOrEqualTo(0)
                  ? // claimedAmount && claimedAmount >= 0
                    formatNumber(
                      new BigNumber(weiToEth(claimedAmount, 18))?.toNumber()
                    )
                  : "0"}
              </div>
            </div>
            {/* <TokenItem>
              <TokenIconContainer>
                <TokenIcon
                  src={require("../../../../assets/icons/aave.svg").default}
                />
                <div>Orasis</div>
              </TokenIconContainer>
              <TokenBalance>
                Claimed : {claimedAmount >= 0 ? claimedAmount : "0"}{" "}
                Tokens
              </TokenBalance>
            </TokenItem> */}
          </TokenDetailsWrapper>
        )}
        {activeTab === "CLAIMABLE" && (
          <TokenDetailsWrapper>
            <div>
              <div className="title">
                <span>Remaining</span>
                <TokenIcon src={Aave} title="Orasis Token" />
                <span>Tokens :</span>
              </div>
              <div className="value">
                {claimableAmount &&
                new BigNumber(claimableAmount)?.isGreaterThanOrEqualTo(0)
                  ? formatNumber(
                      new BigNumber(weiToEth(claimableAmount, 18))?.toNumber()
                    )
                  : "0"}
              </div>
            </div>
            {/* <TokenItem>
              <TokenIconContainer>
                <TokenIcon
                  src={require("../../../../assets/icons/aave.svg").default}
                />
                <div>Orasis</div>
              </TokenIconContainer>
              <TokenBalance>
                Remaining tokens to claim :{" "}
                {claimableAmount >= 0 && !userStatus?.revoked
                  ? claimableAmount
                  : "0"}{" "}
                Tokens
              </TokenBalance>
            </TokenItem> */}
          </TokenDetailsWrapper>
        )}
      </CardContainer>
      {userData?.blacklisted && activeTab === "CLAIMABLE" && (
        <WarningText>Your address has been blacklisted</WarningText>
      )}
      {!isVestingStarted && activeTab === "CLAIMABLE" && (
        <WarningText>Vesting not started yet</WarningText>
      )}
      {/* {userStatus?.blacklisted === true && activeTab === "CLAIMABLE" && (
        <WarningText>Your address has been blacklisted</WarningText>
      )}
      {userStatus?.revoked === true && activeTab === "CLAIMABLE" && (
        <WarningText>Your address has been removed</WarningText>
      )}
      {isVestingStarted === false && activeTab === "CLAIMABLE" && (
        <WarningText>Vesting not started yet</WarningText>
      )} */}
      {activeTab === "CLAIMABLE" && (
        <div className="actionbtnContainer">
          <Button
            isDisabled={!getIsUserClaimable()}
            disabled={!getIsUserClaimable()}
            margin="10px"
            onClick={handleClaim}
            shade="secondary"
          >
            CLAIM TOKENS
          </Button>
        </div>
      )}
    </Container>
  );
}

export default TokenDetails;

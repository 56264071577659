import { useWeb3React } from "@web3-react/core";
import React, { useCallback, useEffect, useState } from "react";
import UserService from "../../../logic/services/user.service";
import Dropdown from "../../../shared/components/dropdown/Dropdown";
import { categoryTypes, monthNames } from "../../../utils";
import TokenDetails from "../components/tokenDetails/TokenDetails";
import {
  Container,
  DropdownContainer,
  Heading,
  LeftContainer,
  NotFoundHeading,
  RightContainer,
  SubHeading,
  // Wrapper,
} from "./style";
import TokenDistributionChart2 from "../components/tokenDistributionChart/TokenDistributionChart2";
import { EmptyState } from "../../../shared/images";
import BigNumber from "bignumber.js";
import { weiToEth } from "../../../utils/helpers";

const Dashboard = (props: any) => {
  const { account } = useWeb3React();
  const [userData, setUserData] = useState<any>(null);
  const [selectedCategory, setselectedCategory] = useState(categoryTypes[0]);
  const [userError, setUserError] = useState<null | boolean>(null);
  const [chartData, setChartData] = useState<any>();

  const handleCategoryChange = (category: any) => {
    setUserError(null);
    setUserData(null);
    setselectedCategory(category);
  };

  useEffect(() => {
    if (account) {
      const encodedSelectedCategory = encodeURIComponent(selectedCategory);

      UserService.getUserDetails(account, encodedSelectedCategory)
        .then((res) => {
          if (res?.error) {
            setUserError(true);
          } else {
            console.log("sdfd", res);

            setUserData(res?.userDetails);
            setUserError(null);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [account, selectedCategory]);

  //* CHART DATA
  const getChartData2 = useCallback(
    (
      totalTokensAllocated: number,
      startTimeStamp: number,
      cliffPeriod: number,
      vestingPeriod: number,
      releaseFrequency: number
    ) => {
      const actualVestingPeriod = vestingPeriod - cliffPeriod;
      const noOfReleaseDates = Math.floor(
        actualVestingPeriod / releaseFrequency
      ); //rounds DOWN to nearest integer
      const tokensReleasedOnDates = totalTokensAllocated / noOfReleaseDates;

      // const totalTokensOnDates = [];

      const firstReleaseDateTimestamp =
        startTimeStamp * 1000 + cliffPeriod * 24 * 60 * 60 * 1000;
      // const releaseDatesTimestamps = [];

      const data = [];

      for (let i = 0; i < noOfReleaseDates; i++) {
        const totalTokensDistributed = tokensReleasedOnDates * (i + 1);
        // totalTokensOnDates.push(tokensReleasedOnDates * (i + 1));
        const releaseDateTimestamp =
          firstReleaseDateTimestamp +
          i * releaseFrequency * 24 * 60 * 60 * 1000;
        // releaseDatesTimestamps.push(
        //   firstReleaseDateTimestamp + i * releaseFrequency * 24 * 60 * 60 * 1000
        // );
        // console.log(releaseDateTimestamp);

        data.push({
          value: totalTokensDistributed,
          date: `${new Date(releaseDateTimestamp).getDate()}/${
            new Date(releaseDateTimestamp).getMonth() + 1
          }/${new Date(releaseDateTimestamp).getFullYear()}`,
        });
      }
      console.log("oiausoe", data);
      return data;
    },
    []
  );

  const getChartData = useCallback(
    (
      totalTokensAllocated: number,
      startTimeStamp: number,
      cliffPeriod: number,
      vestingPeriod: number
    ) => {
      const vestingPeriodInMonths = vestingPeriod / 60 / 60 / 24 / 30;
      const monthlyTokens = totalTokensAllocated / vestingPeriodInMonths;
      // const monthlyTokens = totalTokensAllocated / vestingPeriod;
      const startingDate = new Date(startTimeStamp * 1000);
      const cliffInMonths = cliffPeriod / 60 / 60 / 24 / 30;
      const xAxisLabels: string[] = [];

      const tempDate = startingDate;
      for (let i = 0; i < vestingPeriodInMonths + cliffInMonths; i++) {
        // for (let i = 0; i < vestingPeriod + cliffInMonths; i++) {
        const monthNumber = tempDate.getMonth() > 11 ? 0 : tempDate.getMonth();

        const year = tempDate.getFullYear();
        xAxisLabels.push(
          `${monthNames[monthNumber]} ${year.toString().slice(2)}`
        );
        tempDate.setMonth(monthNumber + 1);

        if (tempDate.getMonth() > 11) {
          tempDate.setFullYear(tempDate.getFullYear() + 1);
        }
      }
      const monthlyTokenList = [];
      for (let i = 1; i <= xAxisLabels.length; i++) {
        if (i <= cliffInMonths) {
          monthlyTokenList.push(0);
          continue;
        }
        monthlyTokenList.push((monthlyTokens * (i - cliffInMonths)).toFixed(2));
      }

      return monthlyTokenList?.map((item, i) => ({
        value: parseFloat(`${item}`),
        date: xAxisLabels?.[i],
      }));
    },
    []
  );

  useEffect(() => {
    if (userData) {
      // setChartData(
      //   getChartData(
      //     userData?.totalTokensAllocated,
      //     userData?.vestingStartTimestamp,
      //     userData?.cliffPeriodInDays * 24 * 60 * 60, //days into seconds
      //     userData?.vestingPeriodInDays * 24 * 60 * 60 //days into seconds
      //   )
      // );
      setChartData(
        getChartData2(
          new BigNumber(
            weiToEth(
              new BigNumber(userData?.totalTokensAllocated)?.toString(),
              18
            )
          )?.toNumber(),
          userData?.vestingStartTimestamp,
          userData?.cliffPeriodInDays,
          userData?.vestingPeriodInDays,
          userData?.releaseFrequencyInDays
        )
      );
    }
  }, [userData, getChartData2]);

  return (
    // <Wrapper>
    <Container>
      <LeftContainer>
        <div>
          {userError === null && userData && (
            <div className="chartCont">
              <Heading>Monthly token distribution</Heading>
              <div className="chartDiv">
                <TokenDistributionChart2 data={chartData} />
              </div>
              {/* <TokenDistributionChart userData={userData} /> */}
            </div>
          )}
          {userError === true && (
            <div className="emptyState">
              <img src={EmptyState} alt="down caret" className="caret" />
              <NotFoundHeading>No Data Available </NotFoundHeading>
            </div>
          )}
        </div>
      </LeftContainer>
      <RightContainer>
        <div>
          <Heading>Token Details</Heading>

          <DropdownContainer>
            <Dropdown
              handleChange={handleCategoryChange}
              options={categoryTypes}
              showEmptyState={false}
            />
          </DropdownContainer>
          {userError === null && userData && (
            <>
              <TokenDetails
                selectedCategory={selectedCategory}
                account={account}
                totalAllocatedAmount={userData?.totalTokensAllocated}
                userData={userData}
              />
            </>
          )}
          {userError === true && (
            <SubHeading>
              We couldn't find your address in {selectedCategory}. Please select
              another category.{" "}
            </SubHeading>
          )}
        </div>
      </RightContainer>
    </Container>
    // </Wrapper>
  );
};
export default Dashboard;

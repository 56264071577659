import styled from "styled-components";
import { colors, screenSizes } from "../../../../shared/styles/theme";
import { BgImg } from "../../../../shared/images";

export const NavContainerWrap = styled.div`
  width: 100%;
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  background: linear-gradient(to bottom, #140f2600, #140f26),
    ${`url(${BgImg}) no-repeat top`};
  /* background: linear-gradient(to bottom, #140f2600, #140f26),
    ${`url(${
    require(`../../../../assets/image/bg-img.svg`).default
  }) no-repeat top`}; */
  background-size: cover;
  /* top: 10px; */
`;

export const NavContainer = styled.div`
  /* background-color: ${colors.white}; */
  /* border: 0.5px solid #ebebf9; */
  /* box-shadow: 8px 8px 16px 4px rgba(137, 143, 150, 0.4); */
  /* border-radius: 20px; */
  height: var(--topBarHeight);
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
  padding: 1rem 0 0;
  /* max-width: 1600px; */
  /* margin: 20px; */
  @media (min-width: ${screenSizes.mediaM}px) {
    height: var(--topBarHeight);
    /* align-items: center; */
  }
`;
export const AppLogoContainer = styled.div`
  margin-left: 0;
  @media (max-width: ${screenSizes.mediaM}px) {
    /* flex: 1; */
    margin-left: 0;
  }
`;

export const AppLogo = styled.img`
  background: #000;
  border-radius: 10px;
  padding: 0 10px;
  cursor: pointer;
  height: 37px;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  @media (min-width: ${screenSizes.mediaS}px) {
    margin-top: 0px;
    height: 37px;
    margin-bottom: 0px;
    width: 128px;
    margin-left: 40px;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    margin-left: 70px;
  }
  @media (min-width: ${screenSizes.mediaXL}px) {
    margin-left: 100px;
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    margin-left: 190px;
  }
`;
export const MenuIconContainer = styled.div`
  display: none;

  div {
    width: 30px;
    height: 4px;
    background-color: ${colors.primaryBlue};
    margin: 4px 10px;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    display: block;
    margin-right: auto;
  }
`;
export const AppLogoName = styled.div`
  font-size: 2rem;
  font-weight: 600;
  margin-right: auto;
  text-align: center;
  width: 9.125rem;
  @media (max-width: ${screenSizes.mediaM}px) {
    display: block;
    margin-right: auto;
  }
`;
export const WalletWrap = styled.div<{
  menu: any;
  connectWallet: any;
  chainId: any;
  showLog: any;
}>`
  /* margin-right: ${(props) => (props.menu ? "0" : "50px")}; */
  display: ${(props) => (props.menu ? "block" : "block")};
  z-index: ${(props) => (props.menu ? "999999" : "0")};
  margin-top: ${(props) => (props.menu ? "72px" : "0")};
  margin-left: auto;
  @media (min-width: ${screenSizes.mediaS}px) {
    display: ${(props) => (props.menu ? "block" : "block")};
    /* margin-right: 20px; */
    /* transform: ${(props) =>
      props.menu &&
      props.connectWallet &&
      props.chainId === 42 &&
      props.showLog === false
        ? "translateX(-43%)"
        : "none"}; */
    display: ${(props) => (props.menu ? "block" : "block")};
  }
  @media (min-width: ${screenSizes.mediaS}px) {
    /* display: ${(props) => (props.menu ? "none" : "block")}; */
    /* margin-right: 20px; */
    /* transform: ${(props) =>
      props.menu &&
      props.connectWallet &&
      props.chainId === 42 &&
      props.showLog === false
        ? "translateX(-43%)"
        : "none"}; */
    display: ${(props) => (props.menu ? "block" : "block")};
  }
`;

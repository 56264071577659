import styled from "styled-components";
import { screenSizes } from "../../../../shared/styles/theme";
import { PurpleClouds } from "../../../../shared/images";

export const StyledRoutesContainerAdmin = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  flex: 1;
  /* max-width: 1600px; */
  margin: auto;
  min-height: calc(100vh);
  padding-top: var(--topBarHeight);
  background: #140f26 url(${PurpleClouds}) no-repeat bottom;
  background-size: cover;
  @media (max-width: ${screenSizes.mediaM}px) {
    margin: auto;
  }
`;
export const StyledRoutesContainerUser = styled.div`
  grid-area: content;
  display: flex;
  flex-direction: column;
  flex: 1;
  background: #140f26 url(${PurpleClouds}) no-repeat bottom;
  background-size: cover;
  /* max-width: 1600px; */
  margin: auto;
  min-height: calc(100vh);
  padding-top: var(--topBarHeight);
  /* margin-right: 10px; */
  @media (max-width: ${screenSizes.mediaM}px) {
    margin-left: 0;
  }
`;

export const StyledRoutes = styled.main`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  position: relative;
  width: 100%;
  padding: 3rem 0 1.5rem;
  /* margin-top: var(--topBarHeight); */
`;

export const MainConatiner = styled.div`
  /* max-width: 1600px; */
  margin: auto;
  /* width: 90%; */
`;

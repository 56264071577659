import { SnackbarProvider } from "notistack";
import React, { useState } from "react";

import { ThemeProvider } from "styled-components";
import { darkTheme, GlobalStyle } from "../../shared/styles/theme";
import Routes from "./components/routes/Routes";
import { LoaderProvider } from "./components/loader/LoaderProvider";

const App = (props: any) => {
  const [currentTheme, setCurrentTheme] = useState({
    name: "DARK",
    theme: darkTheme,
  });

  return (
    <>
      <SnackbarProvider>
        <ThemeProvider theme={currentTheme.theme}>
          <LoaderProvider>
            <GlobalStyle />
            <Routes
              currentTheme={currentTheme}
              setCurrentTheme={setCurrentTheme}
            />
          </LoaderProvider>
        </ThemeProvider>
      </SnackbarProvider>
    </>
  );
};

export default App;

const getLocalRefreshToken = () => {
  const refreshToken = localStorage.getItem("refreshToken");
  return refreshToken;
};

const getLocalAccessToken = () => {
  const accessToken = localStorage.getItem("accessToken");
  return accessToken;
};

const getUser = () => {
  return localStorage.getItem("accessToken");
};

const updateNewAccessTokens = (tokens: any) => {
  localStorage.setItem("accessToken", tokens?.accessToken);
  localStorage.setItem("refreshToken", tokens?.refreshToken);
};

const removeUser = () => {
  localStorage.removeItem("user");
};

const TokenService = {
  getLocalRefreshToken,
  getLocalAccessToken,
  getUser,
  updateNewAccessTokens,
  removeUser,
};

export default TokenService;

import { Field } from "formik";
import styled from "styled-components";
import { colors } from "../../../shared/styles/theme";
import { PurpleClouds } from "../../../shared/images";

export const LoginContainer = styled.div`
  background: #140f26 url(${PurpleClouds}) no-repeat bottom;
  background-size: cover;
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  border-radius: 10px;
  padding: 1rem;
`;
export const Container = styled.div`
  border: 1px solid transparent;
  background: linear-gradient(70.25deg, #ff8800 0.28%, #6514a0 99.72%);
  background-origin: border-box;
  border-radius: 8px;
  height: 25rem;
  width: 75rem;
  .darkBg {
    background: #020106;
    border-radius: 8px;
    padding: 24px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .centered {
      text-align: center;
    }
  }
  h1 {
    font-weight: 700;
  }
  p {
    color: ${colors.lightGrey};
    font-size: 1.25rem;
    margin: 1rem 0 2.25rem;
  }
  .connectBtnBody {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    img {
      height: 1.75rem;
    }
  }
  /* background-color: #fff;
  width: 400px;
  margin: auto;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; */
`;
export const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputField = styled(Field)<any>`
  border: 2px solid ${(props) => props.theme.secondary};
  box-sizing: border-box;
  border-radius: 8px;
  width: 100%;
  height: ${(props) => (props.height ? props.height : "48px")};
  padding: 12px 18px;
  &.textArea {
    height: 100px;
    resize: none;
  }
  margin-top: ${(props) => props.marginTop};
  background: ${(props) => props.theme.primary};
  color: ${(props) => props.theme.secondary};
`;
export const Logo = styled.img`
  height: 45px;
  background: #000;
  border-radius: 10px;
  padding: 10px;
`;

import styled from "styled-components";

import { colors, screenSizes } from "../../../shared/styles/theme";

export const Container = styled.div`
  /* display: flex;
  justify-content: left;
  align-items: start;
  gap: 36px;
  min-height: 460px;
  margin: auto; */
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2.25rem;
  /* height: 28.75rem; */

  /* @media (max-width: ${screenSizes.mediaM}px) {
    flex-direction: column;
  } */
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1fr;

    grid-gap: 2.25rem;
  }
`;
export const Wrapper = styled.div`
  position: relative;
  margin: 20px;
`;

export const Heading = styled.h3`
  font-size: 1.5rem;
  text-align: center;
  margin: 1.5rem 0 2rem;
`;

export const DropdownContainer = styled.div`
  width: 100%;
  align-self: center;
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  @media (min-width: ${screenSizes.mediaM}px) {
    width: auto;
  }
`;
export const NotFoundHeading = styled.div`
  font-size: 2rem;
  font-weight: 700;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  color: ${colors.white};
`;
export const SubHeading = styled.div`
  font-size: 1.25rem;
  font-weight: 500;
  font-style: normal;
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  min-width: 0px;
  /* width: 300px; */
  margin: 10px auto;
  color: ${colors.lightGrey};
`;
export const LeftContainer = styled.div`
  /* flex: 60%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-radius: 20px;
  padding: 10px;
  min-height: 420px;
  box-shadow: rgb(204, 219, 232) 0px 0px 20px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  width: -webkit-fill-available; */
  border: 1px solid transparent;
  background: linear-gradient(70.25deg, #ff8800 0.28%, #6514a0 99.72%);
  background-origin: border-box;
  border-radius: 8px;
  height: 35rem;

  grid-area: 2 / 1 / 3 / 2;

  > div {
    background: #020106;
    border-radius: 8px;
    padding: 1.5rem;
    height: 100%;
    .chartCont {
      height: 100%;
      display: flex;
      flex-direction: column;
      .chartDiv {
        flex-grow: 1;
      }
    }
  }
  .emptyState {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-area: auto;
  }
`;

export const RightContainer = styled.div`
  /* flex: 40%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  align-self: flex-start;
  border-radius: 20px;
  padding: 10px;
  min-height: 420px;
  box-shadow: rgb(204, 219, 232) 0px 0px 20px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  width: -webkit-fill-available; */
  border: 1px solid transparent;
  background: linear-gradient(70.25deg, #ff8800 0.28%, #6514a0 99.72%);
  background-origin: border-box;
  border-radius: 8px;
  height: 35rem;

  > div {
    background: #020106;
    border-radius: 8px;
    padding: 1.5rem;
    height: 100%;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    /* flex: 100%; */
  }
`;

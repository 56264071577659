import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { colors, screenSizes } from "../../../../shared/styles/theme";

export const SideBarContainer = styled.div<any>`
  /* background-color: ${colors.white}; */

  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: 99;
  top: calc(var(--topBarHeight) - 2.5rem);
  /* bottom: 0; */
  overflow-x: hidden;
  /* border: 0.5px solid #ebebf9; */
  /* box-shadow: 8px 8px 16px 4px rgba(137, 143, 150, 0.4); */
  /* border-radius: 8px; */
  /* margin: 20px; */

  /* @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
    position: fixed;
    top: 80px;
    width: calc(100% - 40px);
    z-index: 100000;
  } */
`;
export const SideBarContainerMobile = styled.div<any>`
  background-color: ${colors.white};

  position: fixed;
  z-index: 1;
  top: 90px;
  overflow-x: hidden;
  padding: 10px;
  border: 0.5px solid #ebebf9;
  box-shadow: 8px 8px 16px 4px rgba(137, 143, 150, 0.4);
  border-radius: 20px;
  margin: 10px;
  display: none;
  @media (max-width: ${screenSizes.mediaM}px) {
    display: block;
    position: fixed;
    top: 80px;
    width: calc(100% - 40px);
    z-index: 100000;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transform: ${({ show }) => (show ? "translateY(0)" : "translateY(-250px)")};

    transition: opacity 0.5s;
  }
`;

export const SideNav = styled.div<any>`
  display: flex;
  /* padding: 10px; */
  height: 2.5rem;
  justify-content: center;
  gap: 2rem;
`;

export const NavItemWrapper = styled(NavLink)<any>`
  unset: all;
  text-decoration: none;
  display: flex;
  justify-content: start;
  align-items: center;

  /* padding: 10px; */
`;

export const NavItem = styled.div<any>`
  unset: all;
  display: block;
  /* padding: 8px 8px 8px 24px; */
  text-decoration: none;
  font-size: 18px;
  color: ${colors.black};
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  /* border-radius: 8px; */
  transition: 0.3s;
  /* width: 180px; */
  /* background-color: ${(props) =>
    props.isActive ? colors.primaryBlue : colors.white}; */
  color: ${(props) => (props.isActive ? colors.white : colors.lightGrey)};

  border-bottom: ${(props) => (props.isActive ? "1px solid #fff" : null)};
  /* &&:hover {
    background-color: ${(props) =>
    props.isActive ? colors.primaryBlue : "lightgrey"};
  } */
  @media (max-width: ${screenSizes.mediaM}px) {
    justify-content: center;
    width: 100%;
  }
`;

export const NavItemIcon = styled.img`
  height: 30px;
`;
export const NavItemName = styled.div`
  unset: all;
  font-size: 1.125rem;
  /* line-height: 18px; */
`;
export const AppLogoName = styled.div`
  font-size: 32px;
  font-weight: 600;
  display: none;
  height: 60px;
  line-height: 60px;
  color: #fff;
  margin-left: 10px;
  @media (min-width: ${screenSizes.mediaS}px) {
    display: block;
    margin-right: auto;
  }
`;

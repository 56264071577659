import { useWeb3React } from "@web3-react/core";
import React, { useCallback, useContext, useEffect, useState } from "react";

import { Web3Provider } from "@ethersproject/providers";
import { Switch } from "react-router";
import { Redirect, Route, BrowserRouter as Router } from "react-router-dom";
import authService from "../../../../logic/services/auth.service";
import TokenService from "../../../../logic/services/token.service";
import { ThemeStarter } from "../../../../shared/styles/theme";
import { isTokenExpired } from "../../../../utils/helpers";
import useAuth from "../../../../walletHelpers/useAuth";
import { useEagerConnect } from "../../../../walletHelpers/walletListner";
import AdminDashboard from "../../../admin/dashboard";
import AdminLogin from "../../../admin/login/Login";
import Manage from "../../../admin/manage/Manage";
import UserDashboard from "../../../user/dashboard";
import UserLogin from "../../../user/login/Login";
import history from "../history";
import Navbar from "../navbar";
import SideBar from "../sideBar";
import ProtectedRoute from "./ProtectedRoutes";
import {
  MainConatiner,
  StyledRoutes,
  StyledRoutesContainerAdmin,
  StyledRoutesContainerUser,
} from "./style";
import { Paths } from "./types";
import { Wrapper } from "../../../../shared/styles/styled";
import Footer from "../footer";
import { chain_id, chain_id_in_hex } from "../../../../config";
import Loader from "../../../../shared/components/loader/Loader";
import { LoaderContext } from "../loader/LoaderProvider";

export interface RouteDefinition {
  path: string;
  protected?: boolean;
  redirect?: string;
  component?: any;
  routes?: RouteDefinition[];
  title?: string;
  pathType?: number;
}

interface Props {
  // userLoaded: boolean
}
interface RoutesProps {
  currentTheme: { name: string; theme: ThemeStarter };
  setCurrentTheme: React.Dispatch<
    React.SetStateAction<{
      name: string;
      theme: ThemeStarter;
    }>
  >;
}

const Routes: React.FC<Props & RoutesProps> = (props) => {
  useEagerConnect();
  const currentPath = window.location.pathname;
  const { currentTheme, setCurrentTheme } = props;
  const [showSideBar, setShowSideBar] = useState(false);
  const [isAdminLoggedIn, setIsAdminLoggedIn] = useState(false);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const { chainId } = useWeb3React<Web3Provider>();

  const { logout } = useAuth();

  const { loading } = useContext(LoaderContext);

  useEffect(() => {
    if (isAdminLoggedIn || isUserLoggedIn) {
      const { ethereum }: any = window;
      ethereum.on("chainChanged", (chainId: any) => {
        if (chainId !== chain_id_in_hex) {
          // if (chainId !== "0x13881") {
          logout();
          window.location.reload();
        }
      });
    }
  }, [isAdminLoggedIn, isUserLoggedIn, logout]);

  const updateAccessTokenWithRefreshToken = useCallback(async () => {
    try {
      const response = await authService.refreshTokens();
      if (response?.statusCode === 401) {
        history.push("/admin/login");
        return;
      }
      if (response?.accessToken) {
        TokenService.updateNewAccessTokens(response);
      }
      if (chainId === chain_id) {
        setIsAdminLoggedIn(true);
      }
    } catch (error) {
      history.push("/admin/login");
      setIsAdminLoggedIn(false);
    }
  }, [chainId]);

  useEffect(() => {
    const accessToken = TokenService.getLocalAccessToken();

    if (currentPath.startsWith("/admin/") && accessToken) {
      if (chainId === chain_id) {
        // if (chainId === 80001) {
        if (isTokenExpired(accessToken)) {
          updateAccessTokenWithRefreshToken();
        } else {
          setIsAdminLoggedIn(true);
        }
      }
    }
  }, [chainId, currentPath, updateAccessTokenWithRefreshToken]);

  const toggleSideMenu = () => {
    setShowSideBar(!showSideBar);
  };

  return (
    <Router>
      <Switch>
        {isAdminLoggedIn && (
          <MainConatiner>
            <Navbar
              setIsAdminLoggedIn={setIsAdminLoggedIn}
              setIsUserLoggedIn={setIsUserLoggedIn}
              currentTheme={currentTheme}
              setCurrentTheme={setCurrentTheme}
              toggleSideMenu={toggleSideMenu}
            />
            <SideBar
              showSidebar={showSideBar}
              toggleSideMenu={toggleSideMenu}
              setShowSideBar={setShowSideBar}
            />
            <StyledRoutesContainerAdmin>
              <Wrapper>
                <StyledRoutes>
                  <Route path={Paths.adminLogin} exact>
                    <Redirect to="/dashboard" />
                  </Route>
                  <ProtectedRoute
                    path={Paths.adminDashboard}
                    exact
                    auth={isAdminLoggedIn}
                    component={AdminDashboard}
                  />
                  <ProtectedRoute
                    path={Paths.adminManage}
                    exact
                    auth={isAdminLoggedIn}
                    component={Manage}
                  />
                  <Route path="*">
                    <Redirect to={Paths.adminDashboard} />
                  </Route>
                </StyledRoutes>
              </Wrapper>
            </StyledRoutesContainerAdmin>

            <Loader display={loading} />
            <Footer />
          </MainConatiner>
        )}

        {isUserLoggedIn && (
          <MainConatiner>
            <Navbar
              setIsAdminLoggedIn={setIsAdminLoggedIn}
              setIsUserLoggedIn={setIsUserLoggedIn}
              currentTheme={currentTheme}
              setCurrentTheme={setCurrentTheme}
              toggleSideMenu={toggleSideMenu}
            />
            <StyledRoutesContainerUser>
              <Wrapper>
                <StyledRoutes>
                  <Route path={Paths.userLogin} exact>
                    <Redirect to="/dashboard" />
                  </Route>
                  <ProtectedRoute
                    path={Paths.userDashboard}
                    exact
                    auth={isUserLoggedIn}
                    component={UserDashboard}
                  />
                  <Route path="*">
                    <Redirect to={Paths.userDashboard} />
                  </Route>
                </StyledRoutes>
              </Wrapper>
            </StyledRoutesContainerUser>

            <Loader display={loading} />
            <Footer />
          </MainConatiner>
        )}

        <Route path={Paths.admin}>
          <AdminLogin setIsAdminLoggedIn={setIsAdminLoggedIn} />
        </Route>
        <Route path="*">
          <UserLogin setIsUserLoggedIn={setIsUserLoggedIn} />
        </Route>
      </Switch>
    </Router>
  );
};

export default Routes;

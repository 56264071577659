import styled from "styled-components";

export const FooterContainer = styled.div`
  padding: 3.75rem 0;
  background: #0b0615;
  .section1 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin: 2rem 0 3.125rem;
    .appLogo {
      margin-bottom: 2rem;
      img {
        width: 14.625rem;
      }
    }
    .mediaLinks {
      display: flex;
      gap: 1.25rem;
      flex-wrap: wrap;
      img {
        width: 2.25rem;
      }
    }
  }
  .section2 {
    text-align: center;
    color: #58595b;
    font-size: 1.25rem;
  }
`;

import api from "./api";
import TokenService from "./token.service";

const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

const adminLogin = async (data) => {
  try {
    const response = await api.post("/admin/login", { ...data });
    if (response.data.accessToken) {
      TokenService.updateNewAccessTokens(response.data);
    }
    return response.data;
  } catch (error) {
    console.error("Error during admin login:", error);
    throw error;
  }
};

const logout = () => {
  TokenService.removeUser();
};

const refreshTokens = async () => {
  try {
    const response = await fetch(`${BASE_URL}/admin/refreshToken`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refreshToken: `${TokenService.getLocalRefreshToken()}`,
      }),
    });
    return await response.json();
  } catch (error) {
    console.error("Error refreshing tokens:", error);
    throw error;
  }
};

const getNonce = async () => {
  try {
    const response = await fetch(`${BASE_URL}/admin/nonce`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching nonce from server:", error);
    throw error;
  }
};

const authService = {
  adminLogin,
  refreshTokens,
  logout,
  getNonce,
};

export default authService;
import BigNumber from "bignumber.js";
import jwtDecode from "jwt-decode";

interface DecodedToken {
  exp: number;
}

export const isTokenExpired = (token: string | null): boolean => {
  try {
    if (!token) return false;
    const decoded: DecodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000;

    return decoded.exp < currentTime;
  } catch (error) {
    console.error("Error decoding JWT token:", error);
    return false;
  }
};

export const formatNumber = (num: number) => {
  if (num >= 1000000000) {
    let returnValue = new BigNumber(num / 1000000000)
      .decimalPlaces(2)
      .toString();
    returnValue =
      returnValue.split(".")?.[1]?.length === 1
        ? `${returnValue}0`
        : returnValue; // '2.3'.split('.')=['2', '3'], '2.'.split('.')=['2', ''], '2'.split('.')=['2']
    return returnValue + "B";
  } else if (num >= 1000000) {
    let returnValue = new BigNumber(num / 1000000).decimalPlaces(2).toString();
    returnValue =
      returnValue.split(".")?.[1]?.length === 1
        ? `${returnValue}0`
        : returnValue; // '2.3'.split('.')=['2', '3'], '2.'.split('.')=['2', ''], '2'.split('.')=['2']
    return returnValue + "M";
  } else if (num < 1000) {
    let returnValue = new BigNumber(num).decimalPlaces(2).toString();
    returnValue =
      returnValue.split(".")?.[1]?.length === 1
        ? `${returnValue}0`
        : returnValue; // '2.3'.split('.')=['2', '3'], '2.'.split('.')=['2', ''], '2'.split('.')=['2']
    return returnValue;
  } else {
    return Math.round(num).toLocaleString();
  }
};

export const ethToWei = (value: string, decimal: number) => {
  return new BigNumber(value).times(10 ** decimal).toFixed();
};
export const weiToEth = (value: string, decimal: number) => {
  return new BigNumber(value).dividedBy(10 ** decimal).toFixed();
};

import api from "./api";

const getUserDetails = (address: string, category: string) => {
  return api
    .get(`/user/details?address=${address}&category=${category}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      return {
        error: true,
      };
    });
};
const getUserMerkleProof = (address: string, category: string) => {
  return api
    .get(`/user/merkle-proof?address=${address}&category=${category}`)
    .then((response) => {
      return response?.data?.merkleProof;
    });
};

const UserService = {
  getUserDetails,
  getUserMerkleProof,
};

export default UserService;

import * as React from "react";
import { Close, ModalBody, ModalContent, ModelHead } from "./style";
import { DarkBgCross } from "../images";

const CustomModal = (props: any) => {
  const { show, toggleModal, borderRadius, heading, styles, headIcon } = props;

  const handleClickOutside = (e: any) => {
    if (e.target === e.currentTarget) {
      toggleModal();
    }
  };

  return (
    <ModalBody show={show} onMouseDown={handleClickOutside}>
      <ModalContent borderRadius={borderRadius}>
        <div className="darkBg">
          <ModelHead>
            <h2>
              {heading}
              {/* {headIcon !== "" && (
                <img
                  style={{ margin: "-11px 12px", height: "36px" }}
                  src={`${headIcon}`}
                  alt="head-icon"
                />
              )} */}
            </h2>
            <Close onClick={() => toggleModal(!show)}>
              <img src={DarkBgCross} alt="down caret" className="caret" />
            </Close>
          </ModelHead>
          {props.children}
        </div>
      </ModalContent>
    </ModalBody>
  );
};
export default CustomModal;

import { gql } from "@apollo/client";

export const GET_USER_STATUS = gql`
  query MyQuery($where: User_filter!) {
    users(where: $where) {
      blacklisted
      address
      revoked
      team
    }
  }
`;

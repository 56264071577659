// import "./styles.css";
import React from "react";
import {
  PieChart,
  Pie,
  Legend,
  Cell,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
// import { Chart2Label } from "./style";

const data01 = [
  { name: "Team", value: 15 },
  { name: "Play & Earn", value: 22 },
  { name: "Staking", value: 22 },
  { name: "IEO", value: 2 },
  { name: "Private Sale", value: 11 },
  { name: "Advisors", value: 8 },
  { name: "Marketing", value: 10 },
  { name: "Airdrop", value: 0.5 },
  { name: "Liquidity Reserve", value: 4.5 },
  { name: "Ecosystem Fund", value: 5 },
];

// const data02 = [
//   { name: "Group A", value: 2400 },
//   { name: "Group B", value: 4567 },
//   { name: "Group C", value: 1398 },
//   { name: "Group D", value: 9800 },
//   { name: "Group E", value: 3908 },
//   { name: "Group F", value: 4800 },
// ];
const COLORS = [
  "rgb(101, 20, 160,0.8)",
  "rgb(179, 58, 58,0.8)",
  "rgb(0, 215, 231,0.8)",
  "rgb(51, 166, 52,0.8)",
  "rgb(47, 224, 150,0.8)",
  "rgb(251, 236, 91,0.8)",
  "rgb(3, 70, 242,0.8)",
  "rgb(244, 3, 204,0.8)",
  "rgb(240, 137, 10,0.8)",
  "rgb(0, 105, 217,0.8)",
];
const STROKECOLORS = [
  "#6514a0",
  "#B33A3A",
  "#00D7E7",
  "#32AF34",
  "#2FE096",
  "#FBEC5B",
  "#0346F2",
  "#f403cc",
  "#f0890a",
  "#0069d9",
];
export default function TokenAssignmentChart2() {
  return (
    <ResponsiveContainer>
      <PieChart>
        <Pie
          dataKey="value"
          //   isAnimationActive={false}
          data={data01}
          //   cx={200}
          //   cy={200}
          // outerRadius={"55%"}
          // fill="#8884d8"
          // label={(x) => {
          //   console.log("xx", x);

          //   return x.name;
          // }}
        >
          {data01.map((entry, index) => (
            <Cell
              key={`cell-${index}`}
              fill={COLORS[index % COLORS.length]}
              stroke={STROKECOLORS[index % COLORS.length]}
            />
          ))}
        </Pie>
        <Legend verticalAlign="top" height={36} />
        <Tooltip
          content={({ payload, contentStyle }: any) => {
            if (payload) {
              return (
                <div className="custom-tooltip" style={contentStyle}>
                  <p className="label">{`${payload[0]?.name} : ${payload[0]?.value}%`}</p>
                  {/* <p className="intro">{getIntroOfPage(label)}</p> */}
                  {/* <p className="desc">Anything you want can be displayed here.</p> */}
                </div>
              );
            }

            return null;
          }}
          contentStyle={{
            background: "#020106",
            padding: "5px",
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: "4px",
            color: "#fff !important",
          }}
          labelStyle={{
            color: "#fff !important",
          }}
          // // content={<CustomTooltip />}
          // formatter={(val, key) => [val ? `${val}%` : "0%", key]}
          // labelFormatter={(label) => label}
        />
      </PieChart>
    </ResponsiveContainer>
  );
}

import styled from "styled-components";
import { screenSizes } from "../../../shared/styles/theme";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2.25rem;
  /* height: 28.75rem; */

  /* @media (max-width: ${screenSizes.mediaM}px) {
    flex-direction: column;
  } */
  @media (min-width: ${screenSizes.mediaM}px) {
    grid-template-columns: 1.5fr 1fr;
    grid-template-rows: 1fr;

    grid-gap: 2.25rem;
  }
  /* display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
  @media (max-width: ${screenSizes.mediaXL}px) {
    margin: 10px 40px;
    flex-direction: column;
  } */
`;
export const Wrapper = styled.div`
  position: relative;
  @media (max-width: ${screenSizes.mediaS}px) {
    margin: 0 0;
  }
`;

export const LeftContainer = styled.div`
  border: 1px solid transparent;
  background: linear-gradient(70.25deg, #ff8800 0.28%, #6514a0 99.72%);
  background-origin: border-box;
  border-radius: 8px;
  height: 35rem;

  > div {
    background: #020106;
    border-radius: 8px;
    padding: 1.5rem;
    height: 100%;
    .chartCont {
      height: 100%;
      display: flex;
      flex-direction: column;
      .chartDiv {
        flex-grow: 1;
      }
    }
  }
  /* flex: 60%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-radius: 20px;
  padding: 10px;
  height: 26.25rem;
  box-shadow: rgb(204, 219, 232) 0px 0px 20px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  width: -webkit-fill-available;
  @media (max-width: ${screenSizes.mediaM}px) {
    flex: 100%;
  } */
`;

export const RightContainer = styled.div`
  border: 1px solid transparent;
  background: linear-gradient(70.25deg, #ff8800 0.28%, #6514a0 99.72%);
  background-origin: border-box;
  border-radius: 8px;
  height: 35rem;

  > div {
    background: #020106;
    border-radius: 8px;
    padding: 1.5rem;
    height: 100%;
    .chartCont {
      height: 100%;
      display: flex;
      flex-direction: column;
      .chartDiv {
        flex-grow: 1;
      }
    }
  }
  /* width: 100%;
  border-radius: 20px;
  padding: 10px;
  height: 26.25rem;
  box-shadow: rgb(204, 219, 232) 0px 0px 20px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
  @media (max-width: ${screenSizes.mediaM}px) {
    /* flex: 100%; */
  }
`;

export const Heading = styled.h3`
  font-size: 1.5rem;
  text-align: center;
  margin: 1.5rem 0 2rem;
`;

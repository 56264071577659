export const categoryTypesLabel = [
  "Team",
  "Play & Earn",
  "Staking",
  "IEO",
  "Private Sale",
  "Advisors",
  "Marketing",
  "Airdrop",
  "Liquidity Reserve",
  "Ecosystem Fund",
];
export const categoryTypes = [
  "team",
  "play_and_earn",
  "staking",
  "ieo",
  "private_sale",
  "advisors",
  "marketing",
  "airdrop",
  "liquidity_reserve",
  "ecosystem_fund",
];

export const userStatus = {
  WHITELIST: "WHITELIST",
  BLACKLIST: "BLACKLIST",
  REMOVE: "REMOVE",
};
export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

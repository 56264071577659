import React from "react";
import TokenAssignmentChart from "../components/tokenAssignmentChart/TokenAssignmentChart";
import TokenDistributionChart from "../components/tokenDistributionChart/TokenDistributionChart";
import {
  Container,
  Heading,
  LeftContainer,
  RightContainer,
  Wrapper,
} from "./style";
import TokenAssignmentChart2 from "../components/tokenAssignmentChart/TokenAssignmentChart2";
import TokenDistributionChart2 from "../components/tokenDistributionChart/TokenDistributionChart2";

const Dashboard = (props: any) => {
  return (
    <Wrapper>
      <Container>
        <LeftContainer>
          <div>
            <div className="chartCont">
              <Heading>Monthly token distribution of teams</Heading>
              <div className="chartDiv">
                <TokenDistributionChart2 />
              </div>
            </div>
          </div>
        </LeftContainer>
        <RightContainer>
          <div>
            <div className="chartCont">
              <Heading>Token allocated to teams</Heading>
              <div className="chartDiv">
                <TokenAssignmentChart2 />
              </div>
            </div>
          </div>
          {/* <TokenAssignmentChart /> */}
        </RightContainer>
      </Container>
    </Wrapper>
  );
};
export default Dashboard;

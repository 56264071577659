import { useWeb3React } from "@web3-react/core";
import React from "react";
import ConnectWallet from "../../../../shared/connectWallet";
import {
  AppLogoContainer,
  AppLogoName,
  MenuIconContainer,
  NavContainer,
  NavContainerWrap,
  WalletWrap,
} from "./style";
import { Wrapper } from "../../../../shared/styles/styled";
import { AppLogo } from "../../../../shared/images";

function MenuIcon(props: any) {
  return (
    <MenuIconContainer onClick={props.toggleSideMenu}>
      <div></div>
      <div></div>
      <div></div>
    </MenuIconContainer>
  );
}

const Navbar = (props: any) => {
  const { setIsAdminLoggedIn, setIsUserLoggedIn, toggleSideMenu } = props;

  const [connectWallet, setConnectWallet] = React.useState(false);

  const { chainId } = useWeb3React();

  const [walletAddress, setWalletAddress] = React.useState("");
  const [showLogout, setShowLogout] = React.useState(false);

  React.useEffect(() => {
    //@ts-ignore
    const walletConnect = JSON.parse(localStorage.getItem("walletConnected"));
    setConnectWallet(walletConnect);
    //@ts-ignore
    const address = JSON.parse(localStorage.getItem("address"));
    if (address) {
      var ret = address.replace(/(^"|"$)/g, "");
      setWalletAddress(ret);
    }
  }, [connectWallet, setConnectWallet]);

  return (
    <NavContainerWrap className="header">
      <Wrapper>
        <NavContainer>
          {/* <MenuIcon toggleSideMenu={toggleSideMenu} /> */}

          <AppLogoContainer>
            <AppLogoName>
              <img src={AppLogo} alt="down caret" className="caret" />
            </AppLogoName>
          </AppLogoContainer>

          <WalletWrap
            menu={false}
            connectWallet={connectWallet}
            chainId={chainId}
            showLog={showLogout}
          >
            <ConnectWallet
              connectWallet={connectWallet}
              walletAddress={walletAddress}
              setConnectWallet={setConnectWallet}
              checkWallet={props.checkWallet}
              showWalletContent
              closeWalletModal={() => null}
              showLogout={(e: any) => setShowLogout(e)}
              menu={false}
              setIsAdminLoggedIn={setIsAdminLoggedIn}
              setIsUserLoggedIn={setIsUserLoggedIn}
            />
          </WalletWrap>
        </NavContainer>
      </Wrapper>
    </NavContainerWrap>
  );
};

export default Navbar;

import { ethers } from "ethers";
import { userStatus } from "../../utils";
import { tokenAbi } from "./abi/tokenABI";
import { CONTRACT_ADDRESS } from "./address";
import { chain_id_in_hex } from "../../config";
import api from "../services/api";
import TokenService from "../services/token.service";
import AdminService from "../services/admin.service";
import { closeSnackbar, enqueueSnackbar } from "notistack";

const { ethereum }: any = window;

const provider = ethereum && new ethers.providers.Web3Provider(ethereum);

const signer: any = provider && provider?.getSigner();

export const updateUserStatus = async (
  team: string,
  user: string,
  amount: number,
  type: string
) => {
  try {
    if (CONTRACT_ADDRESS) {
      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );

      const teamHash = await contractInstance.teamHashed(team);
      const contractSigner = await contractInstance.connect(signer);
      if (type === userStatus.WHITELIST) {
        const tx = await contractSigner.whitelistUser(teamHash, user, {
          gasLimit: 1000000,
        });
        return await tx.wait();
      } else if (type === userStatus.BLACKLIST) {
        const tx = await contractSigner.blacklistUser(user, teamHash, amount, {
          gasLimit: 1000000,
        });
        return await tx.wait();
      } else if (type === userStatus.REMOVE) {
        const tx = await contractSigner.revokeUser(user, teamHash, amount, {
          gasLimit: 1000000,
        });
        return await tx.wait();
      }
    } else {
      throw new Error("No CONTRACT_ADDRESS");
    }
  } catch (error) {
    console.log(error);
  }
};

export const toggleBlacklist = async (
  userAddress: string,
  team: string,
  bool: boolean
  // type: string
) => {
  try {
    const res = await api.post(
      `/admin/toggleBlacklist?address=${userAddress}&category=${team}&blacklist=${bool}`
    );
    if (res?.data?.accessToken) {
      TokenService.updateNewAccessTokens(res?.data);
    }
    enqueueSnackbar(
      bool ? "Blacklisted successfully!" : "Whitelisted successfully!",
      {
        variant: "success",
        persist: false,
        autoHideDuration: 5000,
      }
    );
    return res?.data;

    // return api
    //   .post(
    //     `/admin/toggleBlacklist?address=${userAddress}&category=${team}&blacklist=${bool}`
    //   )
    //   .then((response) => {
    //     if (response.data.accessToken) {
    //       TokenService.updateNewAccessTokens(response.data);
    //     }
    //     return response.data;
    //   });
  } catch (error) {
    console.log(error);
    const err = error as {
      response: { data: { error: string; statusCode: number } };
    };
    const errMsg = err?.response?.data?.error;

    enqueueSnackbar(
      errMsg ? errMsg : "Something went wrong. Please try again.",
      {
        variant: "error",
        persist: false,
        hideIconVariant: true,
        autoHideDuration: 5000,
      }
    );
  }
};

export const startVesting = async (team: string, merkleRoot: string) => {
  try {
    if (CONTRACT_ADDRESS) {
      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );

      enqueueSnackbar("Transaction processing...", {
        variant: "info",
        persist: true,
      });

      const teamHash = await contractInstance.teamHashed(team);
      const contractSigner = await contractInstance.connect(signer);
      const tx = await contractSigner.updateMerkleRoot(teamHash, merkleRoot, {
        gasLimit: 1000000,
      });

      // closeSnackbar();
      const res = await tx?.wait();
      closeSnackbar();
      enqueueSnackbar("Vesting started successfully!", {
        variant: "success",
        persist: false,
        autoHideDuration: 5000,
      });
      return res;
    } else {
      throw new Error("No CONTRACT_ADDRESS");
    }
  } catch (error) {
    console.log(error);
    closeSnackbar();
    enqueueSnackbar("Transaction Rejected", {
      variant: "error",
      persist: false,
      hideIconVariant: true,
    });
    throw error;
  }
};

export const removeVesting = async (
  address: string,
  team: string,
  merkleRoot: string
) => {
  // export const removeVesting = async (userAddress: string, team: string) => {
  try {
    if (CONTRACT_ADDRESS) {
      // const res: any = await api
      //   .post(`/admin/removeVesting?address=${userAddress}&category=${team}`)
      //   .then((response) => {
      //     if (response.data.accessToken) {
      //       TokenService.updateNewAccessTokens(response.data);
      //     }
      //     return response.data;
      //   });

      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );
      enqueueSnackbar("Transaction processing...", {
        variant: "info",
        persist: true,
      });
      const teamHash = await contractInstance.teamHashed(team);
      const contractSigner = await contractInstance.connect(signer);
      const tx = await contractSigner.removeVesting(
        address,
        teamHash,
        merkleRoot,
        {
          gasLimit: 1000000,
        }
      );
      const res = await tx?.wait();
      closeSnackbar();
      enqueueSnackbar("Removed successfully!", {
        variant: "success",
        persist: false,
        autoHideDuration: 5000,
      });
      return res;
    } else {
      throw new Error("No CONTRACT_ADDRESS");
    }
  } catch (error) {
    console.log(error);
    closeSnackbar();
    enqueueSnackbar("Transaction Rejected", {
      variant: "error",
      persist: false,
      hideIconVariant: true,
    });
  }
};

export const updateMerkleRoot = async (team: string) => {
  try {
    if (CONTRACT_ADDRESS) {
      const res: any = await AdminService.getMerkleRoot(team);

      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );
      console.log("sdfsdf", res);

      const teamHash = await contractInstance.teamHashed(team);
      const contractSigner = await contractInstance.connect(signer);
      const tx = await contractSigner.updateMerkleRoot(
        teamHash,
        res?.merkleRoot,
        {
          gasLimit: 1000000,
        }
      );
      return await tx.wait();
    } else {
      throw new Error("No CONTRACT_ADDRESS");
    }
  } catch (error) {
    console.log(error);
    enqueueSnackbar("Transaction Rejected", {
      variant: "error",
      persist: false,
      hideIconVariant: true,
      autoHideDuration: 5000,
    });
    throw error;
  }
};

export const getUserClaimableTokens = async (
  team: string,
  user: string,
  totalAllocated: number
) => {
  try {
    if (CONTRACT_ADDRESS && ethereum?.chainId === chain_id_in_hex) {
      // // if (CONTRACT_ADDRESS && window?.ethereum?.chainId === "0x13881") {

      const res: any = await api
        .get(`/user/currentClaimable?address=${user}&category=${team}`)
        .then((response) => {
          if (response.data.accessToken) {
            TokenService.updateNewAccessTokens(response.data);
          }
          return response.data;
        });

      console.log("sdf", res);

      return res;
      // const contractInstance = new ethers.Contract(
      //   CONTRACT_ADDRESS,
      //   tokenAbi,
      //   provider
      // );

      // const teamHash = await contractInstance.teamHashed(team);
      // try {
      //   const claimableAmount = await contractInstance.claimable(
      //     teamHash,
      //     user,
      //     totalAllocated
      //   );
      //   return claimableAmount;
      // } catch (error) {
      //   return 0;
      // }
    } else {
      throw new Error("No CONTRACT_ADDRESS or window.ethereum.chainId");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getUserClaimedTokens = async (team: string, user: string) => {
  try {
    if (CONTRACT_ADDRESS && ethereum.chainId === chain_id_in_hex) {
      // if (CONTRACT_ADDRESS && window.ethereum.chainId === "0x13881") {
      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );
      const teamHash = await contractInstance.teamHashed(team);

      const claimedAmount = await contractInstance.userClaimed(teamHash, user);
      // const claimedAmount = await contractInstance.claimedAmount(
      //   teamHash,
      //   user
      // );
      console.log("claimedAmountRes", claimedAmount?.toString());

      return claimedAmount;
    } else {
      throw new Error("No CONTRACT_ADDRESS or window.ethereum.chainId");
    }
  } catch (error) {
    console.log(error);
  }
};

export const claimTokens = async (
  team: string,
  merkleProof: any,
  sign: string,
  totalTokensAllocated: number,
  amount: number,
  salt: string
  // user: string
) => {
  try {
    if (CONTRACT_ADDRESS) {
      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );
      console.log(totalTokensAllocated, amount);

      const teamHash = await contractInstance.teamHashed(team);
      const contractSigner = await contractInstance.connect(signer);

      const tx = await contractSigner.claim(
        teamHash,
        merkleProof,
        sign,
        `${totalTokensAllocated}`,
        `${amount}`,
        salt,
        {
          gasLimit: 1000000,
        }
      );
      const reciept: any = await tx.wait();
      if (reciept?.status === 1) {
        return reciept;
      }
    } else {
      throw new Error("No CONTRACT_ADDRESS");
    }
  } catch (error) {
    console.log(error);
    throw error;
  }
};

export const getIsUserRemoved = async (team: string, user: string) => {
  try {
    if (CONTRACT_ADDRESS && ethereum.chainId === chain_id_in_hex) {
      // if (CONTRACT_ADDRESS && window.ethereum.chainId === "0x13881") {
      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );
      const teamHash = await contractInstance.teamHashed(team);
      const res = await contractInstance.revokedUser(teamHash, user);

      const isBlacklisted = res[0];
      return isBlacklisted;
    } else {
      throw new Error("No CONTRACT_ADDRESS or window.ethereum.chainId");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getIsVestingStarted = async (team: string) => {
  try {
    if (CONTRACT_ADDRESS && ethereum.chainId === chain_id_in_hex) {
      // if (CONTRACT_ADDRESS && window.ethereum.chainId === "0x13881") {
      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );

      const teamHash = await contractInstance.teamHashed(team);

      const res = await contractInstance.receiver(teamHash);

      const isVestingStarted = res[3];
      return isVestingStarted;
    } else {
      throw new Error("No CONTRACT_ADDRESS or window.ethereum.chainId");
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTeamHash = async (team: any) => {
  try {
    if (CONTRACT_ADDRESS && tokenAbi && provider) {
      const contractInstance = new ethers.Contract(
        CONTRACT_ADDRESS,
        tokenAbi,
        provider
      );
      const teamHash = await contractInstance.teamHashed(team);
      return teamHash;
    } else {
      throw new Error("No CONTRACT_ADDRESS or tokenAbi or provider");
    }
  } catch (error) {
    console.log(error);
  }
};

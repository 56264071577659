import { Web3Provider } from "@ethersproject/providers";
import { useWeb3React } from "@web3-react/core";
import { ethers } from "ethers";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect } from "react";
import { SiweMessage } from "siwe";
import authService from "../../../logic/services/auth.service";
import TokenService from "../../../logic/services/token.service";
import { Button } from "../../../shared/components/button";
import useAuth from "../../../walletHelpers/useAuth";
import { Container, LoginContainer } from "./styles";
import { chain_id } from "../../../config";
import { Wallet } from "../../../shared/images";

const domain = window.location.host;
const origin = window.location.origin;
const { ethereum }: any = window;
const provider = ethereum && new ethers.providers.Web3Provider(ethereum);
const signer = provider && provider.getSigner();

async function createSiweMessage(address: any, statement: any) {
  try {
    const { nonce } = await authService.getNonce();
    if (!nonce) throw new Error('Nonce not received');

    const message = new SiweMessage({
      domain,
      address,
      statement,
      uri: origin,
      version: "1",
      chainId: chain_id,
      nonce: nonce,
    });
    return message.prepareMessage();
  } catch (error) {
    console.error('Error creating SIWE message:', error);
    throw error;
  }
}

const Login = (props: any) => {
  const { setIsAdminLoggedIn } = props;
  const { login } = useAuth();
  const { account } = useWeb3React<Web3Provider>();
  const { enqueueSnackbar } = useSnackbar();

  const signMessage = useCallback(async () => {
    try {
      if (!signer) {
        enqueueSnackbar("No signer available. Ensure the wallet is connected properly.", {
          variant: "error",
          persist: false,
          hideIconVariant: true,
        });
        return;
      }

      const address = await signer.getAddress();
      console.log('Account address:', address);

      const message = await createSiweMessage(address, "Sign in with Ethereum to the app.");
      const sign = await signer.signMessage(message);

      const loginData = {
        address: account,
        signatureMessage: message,
        signature: sign,
      };

      console.log('Attempting admin login with data:', loginData);

      authService
        .adminLogin(loginData)
        .then((json) => {
          setIsAdminLoggedIn(true);
        })
        .catch((error) => {
          console.error('Admin login error:', error);
          enqueueSnackbar("Please switch to admin wallet and try again.", {
            variant: "error",
            persist: false,
            hideIconVariant: true,
          });
        });
    } catch (err) {
      console.error('Error during signMessage process:', err);
      enqueueSnackbar("Something went wrong.", {
        variant: "error",
        persist: false,
        hideIconVariant: true,
      });
    }
  }, [account, enqueueSnackbar, setIsAdminLoggedIn]);

  const connectWalletAndLogin = async () => {
    try {
      const { ethereum }: any = window;
      console.log("Login", ethereum);
      await login(1);
      localStorage.setItem("connectorId", JSON.stringify(1));
      console.log('Connected account:', account);
      if (account) {
        await signMessage();
      }
    } catch (error) {
      console.error('Error during wallet connection:', error);
    }
  };

  useEffect(() => {
    const accessToken = TokenService.getLocalAccessToken();
    console.log("Use Effect Access Token", accessToken, account);
    if (account && !accessToken) {
      signMessage();
    }
  }, [account, signMessage]);

  const openLinkInNewTab = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <LoginContainer>
      <Container>
        <div className="darkBg">
          <div className="centered">
            <h1>Welcome to Orasis</h1>
            {ethereum ? (
              <p>Please connect with the admin wallet</p>
            ) : (
              <p>Please install metamask wallet</p>
            )}
            {ethereum ? (
              <Button onClick={connectWalletAndLogin}>
                <div className="connectBtnBody">
                  <img
                    src={Wallet}
                    alt="Wallet icon"
                  />
                  <span>CONNECT WALLET</span>
                </div>
              </Button>
            ) : (
              <Button onClick={() => openLinkInNewTab("https://metamask.io/")}>
                <div className="connectBtnBody">
                  <img
                    src={Wallet}
                    alt="Wallet icon"
                  />
                  <span>Install Metamask</span>
                </div>
              </Button>
            )}
          </div>
        </div>
      </Container>
    </LoginContainer>
  );
};

export default Login;
import { createGlobalStyle } from "styled-components";

// import { rgba } from 'polished'

// import url("https://p.typekit.net/p.css?s=1&k=mbf7fkk&ht=tk&f=39347.39348.39349&a=1312176&app=typekit&e=css");

import Bold from "../../assets/fonts/DINPro-Bold.ttf";
// import ExtraLight from "../../assets/fonts/SourceCodePro-ExtraLight.ttf";
import Light from "../../assets/fonts/DINPro-Light.ttf";
import Medium from "../../assets/fonts/DINPro-Medium.ttf";
import Regular from "../../assets/fonts/DINPro.ttf";
// import SemiBold from "../../assets/fonts/SourceCodePro-SemiBold.ttf";

export interface Colors {
  white: string;
  offWhite: string;
  grey: string;
  lightGrey: string;
  red: string;
  farmCardBackground: string;
  lightGreen: string;
  green: string;
  purple: string;
  base: string;
  black: string;
  greenGradient: string;
  yellowGradient: string;
  yellow: string;
  whiteGradient: string;
  neon: string;
  primaryBlue: string;
  primaryBlueDark: string;
}

export const colors: Colors = {
  white: "#ffffff",
  offWhite: "#F6F7FB",
  grey: "#636363",
  // grey: "#808080",
  lightGrey: "#A7A9AC",
  // lightGrey: "#d3d3d3",
  red: "#B33A3A",
  farmCardBackground: "#2E293F",
  neon: "#00D7E7",
  lightGreen: "#2FE096",
  green: "#32AF34",
  purple: "#6D0892",
  // green: "#01D14E",
  base: "#1F1D29",
  black: "#1D1A28",
  greenGradient: "#04A73F",
  yellow: "#FBEC5B",
  yellowGradient: "#EBDC47",
  whiteGradient: "#C4C4C4",
  primaryBlue: "#0346F2",
  primaryBlueDark: "#0069d9",
};

export interface ThemeStarter {
  [propName: string]: string | undefined;
  primary: string;
  secondary: string;
}

export const lightTheme: ThemeStarter = {
  primary: colors.white,
  secondary: colors.black,
};

export const darkTheme: ThemeStarter = {
  primary: colors.black,
  secondary: colors.white,
};

export interface ScreenSizes {
  mediaXXS: number;
  mediaXS: number;
  mediaS: number;
  mediaM: number;
  mediaL: number;
  mediaXL: number;
  mediaXXL: number;
  mediaLX: number;
  mediaLXX: number;
}
export const screenSizes: ScreenSizes = {
  mediaXXS: 250,
  mediaXS: 400,
  mediaS: 640,
  mediaM: 800,
  mediaL: 1024,
  mediaXL: 1280,
  mediaXXL: 1480,
  mediaLX: 1800,
  mediaLXX: 2000,
};

export const GlobalStyle = createGlobalStyle`
@font-face {
  font-family: "DINPro";
  src: url(${Bold});
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "DINPro";
  src: url(${Medium});
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "DINPro";
  src: url(${Regular});
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "DINPro"; 
  src: url(${Light});
  font-weight: 300;
  font-style: normal;
}
/* @font-face {
  font-family: Bold;
  src: url(${Bold});
}
@font-face {
  font-family: Light; 
  src: url(${Light});
}
@font-face {
  font-family: Medium;
  src: url(${Medium});
}
@font-face {
  font-family: Regular;
  src: url(${Regular});
}
 */

*, *::before, *::after {
  box-sizing: border-box;
}
* {
  margin: 0;
}
html, body {
  /* font-display: block; */
  font-size: 9px;
  @media only screen and (min-width: ${screenSizes.mediaXXS}px) {
    font-size: 10px;
  }
  @media only screen and (min-width: ${screenSizes.mediaXS}px) {
    font-size: 11px;
  }
  @media only screen and (min-width: ${screenSizes.mediaS}px) {
    font-size: 11px;
  }
  @media only screen and (min-width: ${screenSizes.mediaM}px) {
    font-size: 12px;
  }
  @media only screen and (min-width: ${screenSizes.mediaL}px) {
    font-size: 13px;
  }
  @media only screen and (min-width: ${screenSizes.mediaXL}px) {
    font-size: 14px;
  }
  @media only screen and (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 16px;
  }
  @media only screen and (min-width: ${screenSizes.mediaLX}px) {
    font-size: 16px;
  }
  @media only screen and (min-width: ${screenSizes.mediaXXL}px) {
    font-size: 16px;
  }
}
body {
  font-family: 'DINPro' ;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  background: ${(props) => `#140f26 `};
  /* background-size: cover; */
  color: ${colors.white};
  
  margin:0;
  padding:0;
  min-height: 100vh;
  /* background: ${colors.offWhite}; */
  
  /* -webkit-font-smoothing: antialiased;
  overflow: overlay; */
  /* color-scheme: dark; */
}
img, picture, video, canvas, svg {
  display: block;
  max-width: 100%;
}
input, button, textarea, select {
  font: inherit;
}
p, h1, h2, h3, h4, h5, h6 {
  overflow-wrap: break-word;
}
a{
  text-decoration: none;
}
#root {
  min-height: 100vh;
  --topBarHeight: 88px;
}
`;

import { useWeb3React } from "@web3-react/core";
import BigNumber from "bignumber.js";
import { ethers } from "ethers";
import { useEffect, useState } from "react";
import { BIG_ZERO } from "../utils/bignumber";

export const useGetWalletBalance = () => {
  const [balance, setBalance] = useState<any>(BIG_ZERO);
  const { account, library } = useWeb3React();

  useEffect(() => {
    const fetchBalance = async () => {
      // @ts-ignore
      const provider = new ethers.providers.Web3Provider(window?.ethereum);
      if (account) {
        const balanceInWei = await provider.getBalance(account);
        const balanceInEther = ethers.utils.formatEther(balanceInWei);
        // @ts-ignore
        setBalance(new BigNumber(balanceInEther));
      }
    };
    fetchBalance();
  }, [account, setBalance, library]);

  return { balance };
};

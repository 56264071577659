import React, { useEffect, useState } from "react";
import { Paths } from "../routes/types";
import {
  NavItem,
  NavItemIcon,
  NavItemName,
  NavItemWrapper,
  SideBarContainer,
  SideBarContainerMobile,
  SideNav,
} from "./style";
import { Wrapper } from "../../../../shared/styles/styled";

function SideBar(props: any) {
  const { showSidebar, setShowSideBar } = props;
  const [show, setshow] = useState(props.showSidebar || false);
  const [activeRoute, setActiveRoute] = useState("dashboard");

  useEffect(() => {
    setshow(showSidebar);
  }, [showSidebar]);

  return (
    <>
      <SideBarContainer className="sidebar" show={show}>
        <Wrapper>
          <SideNav>
            <NavItemWrapper
              to={Paths.adminDashboard}
              onClick={() => setShowSideBar(false)}
              isActive={(match: any, location: any) => {
                if (match) {
                  setActiveRoute("dashboard");
                }
                return match;
              }}
            >
              <NavItem isActive={activeRoute === "dashboard"}>
                {/* <NavItemIcon
                  src={
                    require(`../../../../assets/icons/dashboard${
                      activeRoute === "dashboard" ? "White" : "Black"
                    }.png`).default
                  }
                /> */}
                <NavItemName>Dashboard</NavItemName>{" "}
              </NavItem>
            </NavItemWrapper>
            <NavItemWrapper
              to={Paths.adminManage}
              onClick={() => setShowSideBar(false)}
              isActive={(match: any, location: any) => {
                if (match) {
                  setActiveRoute("manage");
                }
                return match;
              }}
            >
              <NavItem isActive={activeRoute === "manage"}>
                {/* <NavItemIcon
                  src={
                    require(`../../../../assets/icons/manage${
                      activeRoute === "manage" ? "White" : "Black"
                    }.png`).default
                  }
                /> */}

                <NavItemName>Manage</NavItemName>
              </NavItem>
            </NavItemWrapper>
          </SideNav>
        </Wrapper>
      </SideBarContainer>
      {/* <SideBarContainerMobile className='sidebarMob' show={show}>
                <SideNav >


                    <NavItemWrapper to={Paths.adminDashboard}
                        onClick={() => setShowSideBar(false)}
                        isActive={(match: any, location: any) => {
                            if (match) {
                                setActiveRoute("dashboard")
                            }
                            return match;
                        }}>
                        <NavItem
                            isActive={activeRoute === "dashboard"}
                        >
                            <NavItemIcon src={require(`../../../../assets/icons/dashboard${activeRoute === 'dashboard' ? 'White' : 'Black'}.png`).default} />


                            <NavItemName>Dashboard</NavItemName> </NavItem>
                    </NavItemWrapper>
                    <NavItemWrapper to={Paths.adminManage}
                        onClick={() => setShowSideBar(false)}
                        isActive={(match: any, location: any) => {
                            if (match) {
                                setActiveRoute("manage")
                            }
                            return match;
                        }}>

                        <NavItem isActive={activeRoute === "manage"}>
                            <NavItemIcon src={require(`../../../../assets/icons/manage${activeRoute === 'manage' ? 'White' : 'Black'}.png`).default} />

                            <NavItemName>Manage</NavItemName></NavItem>
                    </NavItemWrapper>
                </SideNav>
            </SideBarContainerMobile> */}
    </>
  );
}

export default SideBar;

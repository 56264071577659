import axios from "axios";
import authService from "./auth.service";
import TokenService from "./token.service";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  // headers: {
  //   "Content-Type": "application/json",
  //   "Access-Control-Allow-Origin": "*",
  //   "ngrok-skip-browser-warning": "69420",
  // },
});

instance.interceptors.request.use(
  (config) => {
    const token = TokenService.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["ngrok-skip-browser-warning"] = "69420";
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const response = await authService.refreshTokens();

          if (response?.statusCode === 401) {
            localStorage.clear();
            // @ts-ignore
            window.location = "/admin/login";
          }
          if (response?.accessToken) {
            TokenService.updateNewAccessTokens(response);
          }
          const token = TokenService.getLocalAccessToken();
          originalConfig.headers.Authorization = `Bearer ${token}`;
          return instance(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  }
);

export default instance;

import * as React from "react";

type LoaderProviderProps = {
  children: React.ReactNode;
};

type LoaderContextType = {
  loading: boolean;
  setLoading: (bool: boolean) => void;
};
export const LoaderContext = React.createContext<LoaderContextType>({
  loading: false,
  setLoading: (bool: boolean) => {},
});

export const LoaderProvider: React.FC<LoaderProviderProps> = ({ children }) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <LoaderContext.Provider
      value={{ loading, setLoading: (bool: boolean) => setLoading(bool) }}
    >
      {children}
    </LoaderContext.Provider>
  );
};

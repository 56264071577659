import styled, { css } from "styled-components";
import { colors } from "../../styles/theme";

export interface ButtonProps {
  margin?: string;
  priority?: string;
  type?: string;
  background?: string;
  color?: string;
  gradient?: string;
  isDisabled?: boolean;
}

const buttonStyles = css`
  margin: ${(props: ButtonProps) => props.margin};
  height: 3.5rem;
  display: inline-block;
  unset: all;
  display: inline-block;
  font-weight: 600;
  /* color: #212529; */
  text-align: center;
  border: 1px solid transparent;
  padding: 0.75rem 1.5rem;
  font-size: 1.375rem;
  line-height: 1.5;
  border-radius: 4px;
  color: ${colors.white};
  /* background-color: ${colors.green}; */
  opacity: ${(props: ButtonProps) => (props.isDisabled ? 0.4 : 1)};
  box-shadow: 0px 1px 0px #ffffff;
  /* pointer-events: ${(props: ButtonProps) => props.isDisabled && "none"}; */
  cursor: ${(props: ButtonProps) =>
    props.isDisabled ? "not-allowed" : "pointer"};
`;

export const Button = styled.button<any>`
  ${buttonStyles}
  background-color: ${(props) =>
    props?.shade === "secondary" ? colors.purple : colors.green};
  &:hover {
    background-color: ${(props) =>
      props?.shade === "secondary" ? colors.purple : colors.green};
    /* background-color: ${(props: ButtonProps) =>
      props.isDisabled ? colors.grey : colors.primaryBlueDark}; */
  }
`;

import React from "react";
import { FooterContainer } from "./style";
import { Wrapper } from "../../../../shared/styles/styled";
import {
  AppLogo,
  Discord,
  Facebook,
  Instagram,
  Medium,
  Telegram,
  Tiktok,
  Twitter,
} from "../../../../shared/images";

const Footer = () => {
  return (
    <FooterContainer>
      <Wrapper>
        <div className="section1">
          <div className="appLogo">
            <img
              src={AppLogo}
              // src={require("../../../../assets/icons/appLogo.svg").default}
              alt="down caret"
              className="caret"
            />
          </div>
          <div className="mediaLinks">
            <img
              src={Discord}
              // src={require("../../../../assets/icons/discord.svg").default}
              alt="down caret"
              className="caret"
            />
            <img
              src={Medium}
              // src={require("../../../../assets/icons/medium.svg").default}
              alt="down caret"
              className="caret"
            />
            <img src={Tiktok} alt="down caret" className="caret" />
            <img src={Twitter} alt="down caret" className="caret" />
            <img src={Facebook} alt="down caret" className="caret" />
            <img src={Telegram} alt="down caret" className="caret" />
            <img src={Instagram} alt="down caret" className="caret" />
          </div>
        </div>
        <div className="section2">
          <p>
            Copyright &copy; 2022{" "}
            {/* <a href="https://github.com/seunghyun-kim"> */}
            bountytemple.com | Orasis Studio SDN
            {/* </a> */}. BHD.Seunghyun Kim
          </p>
        </div>
      </Wrapper>
    </FooterContainer>
  );
};

export default Footer;

import styled from "styled-components";
import { colors, screenSizes } from "../../../shared/styles/theme";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;
export const Wrapper = styled.div`
  background-color: #12132b;
  position: relative;

  @media (max-width: ${screenSizes.mediaS}px) {
    margin: 0 0;
  }
`;
export const StyledInput = styled.input`
  background-color: #020106;
  border: 2px solid #3c3b3e;
  outline: none;
  color: ${colors.white};
  border-radius: 8px;
  height: 5rem;
  padding-left: 2rem;
  ::placeholder {
    color: ${colors.white};
  }
  /* font-size: 14px;
  color: rgb(36, 35, 42);
  font-size: 16px;
  line-height: 20px;
  min-height: 28px;
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid transparent;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
  background: rgb(251, 251, 251);
  transition: all 0.1s ease 0s;
  :focus {
    border: 2px solid rgb(124, 138, 255);
  } */
`;
export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
`;

export const TextArea = styled.textarea`
  font-size: 14px;
  max-width: 100%;
  color: rgb(36, 35, 42);
  font-size: 16px;
  line-height: 20px;
  min-height: 28px;
  border-radius: 4px;
  padding: 8px 16px;
  border: 2px solid transparent;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 3px, rgb(0 0 0 / 24%) 0px 1px 2px;
  background: rgb(251, 251, 251);
  transition: all 0.1s ease 0s;
  :focus {
    border: 2px solid rgb(124, 138, 255);
  }
`;
export const Heading = styled.h2`
  margin-bottom: 1rem;
`;
export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  padding: 10px 0 10px;
  gap: 10px;
`;
export const PreviewCSV = styled.div`
  overflow: scroll;
`;
export const CsvViewCard = styled.pre`
  border-radius: 30px;
  background: #ffffff;
  box-shadow: 27px 27px 54px #ababab, -27px -27px 54px #ffffff;
  padding: 20px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
`;
export const CsvPlaceholder = styled.div`
  /* border: 3px dashed #0346f269;
  box-shadow: 8px 8px 16px 4px rgba(137, 143, 150, 0.4);
  border-radius: 20px;
  text-align: center;
  color: ${colors.grey};
  background: ${colors.white}; */
  border: 1px dashed transparent;
  background: linear-gradient(70.25deg, #ff8800 0.28%, #6514a0 99.72%);
  background-origin: border-box;
  border-radius: 8px;
  > div {
    line-height: 6.25rem;
    /* padding-left: 2rem; */
    text-align: center;
    background: #020106;
    border-radius: 8px;
  }
`;
export const TableContainer = styled.table`
  border-collapse: collapse;
  width: 100%;

  th,
  td {
    text-align: left;
    padding: 8px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
    color: #000;
  }

  th {
    background-color: ${colors.primaryBlue};
    color: white;
    text-transform: capitalize;
  }
`;

import { useSnackbar } from "notistack";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  removeVesting,
  startVesting,
  toggleBlacklist,
  updateMerkleRoot,
  updateUserStatus,
} from "../../../logic/contracts/tokenContractService";
import UploadService from "../../../logic/services/upload.service";
import UserService from "../../../logic/services/user.service";
import { Button } from "../../../shared/components/button";
import Dropdown from "../../../shared/components/dropdown/Dropdown";
import { categoryTypes, userStatus } from "../../../utils";
import {
  ButtonContainer,
  Container,
  CsvPlaceholder,
  Heading,
  InputWrapper,
  PreviewCSV,
  StyledInput,
  TableContainer,
} from "./style";
import AdminService from "../../../logic/services/admin.service";
import { weiToEth } from "../../../utils/helpers";
import BigNumber from "bignumber.js";
import { LoaderContext } from "../../app/components/loader/LoaderProvider";

const Manage = (props: any) => {
  const [file, setFile] = useState();
  const [array, setArray] = useState([]);
  const [csvContents, setCsvContents] = useState("");
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedCategoryWhitelist, setSelectedCategoryWhitelist] =
    useState("");
  const [selectedCategoryBlacklist, setSelectedCategoryBlacklist] =
    useState("");
  const [selectedCategoryRemove, setSelectedCategoryRemove] = useState("");
  const [selectedCategoryToSync, setSelectedCategoryToSync] = useState("");
  const [whitelistAddress, setWhitelistAddress] = useState("");
  const [blacklistAddress, setBlacklistAddress] = useState("");
  const [removeAddress, setRemoveAddress] = useState("");

  const { setLoading } = useContext(LoaderContext);

  const headerKeys = useMemo(
    () => Object.keys(Object.assign({}, ...array)),
    [array]
  );

  const chooseCsvRef = useRef(null);

  const fileReader = useMemo(() => new FileReader(), []);

  const handleOnChange = useCallback((e: any) => {
    setFile(e.target.files[0]);
    e.target.value = null;
  }, []);

  const handleUserStatusChange = async (
    address: string,
    category: string,
    type: string
  ) => {
    try {
      closeSnackbar();

      if (!category) {
        enqueueSnackbar("Please select category", {
          variant: "error",
          persist: false,
          hideIconVariant: true,
        });
        return;
      }
      if (!address) {
        enqueueSnackbar("Please enter user address", {
          variant: "error",
          persist: false,
          hideIconVariant: true,
        });
        return;
      }
      const userDetails = await UserService.getUserDetails(address, category);
      const totalAllocatedTokens = new BigNumber(
        weiToEth(
          new BigNumber(userDetails?.totalAllocatedTokens)?.toString(),
          18
        )
      )?.toNumber();

      enqueueSnackbar(`Transaction processing...`, {
        variant: "info",
        persist: true,
      });

      await updateUserStatus(category, address, totalAllocatedTokens, type);
      closeSnackbar();

      enqueueSnackbar(`User  ${type.toLowerCase()} success!`, {
        variant: "success",
        persist: false,
      });
    } catch (error) {
      closeSnackbar();
      enqueueSnackbar(`User ${type.toLowerCase()} failed`, {
        variant: "error",
        persist: false,
        hideIconVariant: true,
      });
    }
  };

  const csvFileToArray = useCallback((string: string) => {
    setCsvContents(string);
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");

    const array: any = csvRows.map((i) => {
      const values = i.split(",");
      const obj = csvHeader.reduce((object: any, header: any, index: any) => {
        object[header] = values[index];
        return object;
      }, {});
      return obj;
    });

    setArray(array);
  }, []);

  useEffect(() => {
    if (file) {
      fileReader.onload = function (event: any) {
        const text = event.target.result;
        csvFileToArray(text);
      };
      fileReader.readAsText(file);
    } else {
      setCsvContents("");
      setArray([]);
    }
  }, [file, csvFileToArray, fileReader]);

  const handleChange = useCallback((value: any) => {
    setSelectedCategory(value);
  }, []);

  const uploadCsv = useCallback(async () => {
    try {
      if (!csvContents) {
        enqueueSnackbar("Please select CSV to upload", {
          variant: "error",
          persist: false,
          hideIconVariant: true,
        });
        return;
      } else if (!selectedCategory) {
        enqueueSnackbar("Please select category", {
          variant: "error",
          persist: false,
          hideIconVariant: true,
        });
        return;
      }

      setLoading(true);
      const res = await UploadService.uploadCsv(file, selectedCategory);
      // console.log("uploadCsvRes", res);
      if (res?.merkleRoot) {
        try {
          const res1 = await startVesting(selectedCategory, res?.merkleRoot);
          setFile(undefined);
          setSelectedCategory("");
          setLoading(false);
        } catch (error) {
          console.log("lop", error);
          setFile(undefined);
          setSelectedCategory("");
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setFile(undefined);
      setSelectedCategory("");
      setLoading(false);
    }
  }, [csvContents, enqueueSnackbar, file, selectedCategory]);

  const minifyWalletAddress = useCallback((walletAddress: string) => {
    if (walletAddress.length <= 10) {
      return walletAddress;
    }

    const firstPart = walletAddress.slice(0, 6);
    const lastPart = walletAddress.slice(-4);

    return `${firstPart}...${lastPart}`;
  }, []);

  const replaceUnderscoresWithSpaces = useCallback((str: string) => {
    return str.replace(/_/g, " ");
  }, []);

  return (
    <Container>
      <InputWrapper>
        <Heading>Distribute tokens</Heading>
        {/* {csvContents ? <TextArea disabled rows={10} value={csvContents} onChange={(e: any) => {
          setCsvContents(e.target.value)
        }} /> : <CsvPlaceholder>Please select a CSV file to see preview</CsvPlaceholder>} */}

        {headerKeys?.length > 0 ? (
          <PreviewCSV>
            {/* <CsvViewCard>{csvContents}</CsvViewCard> */}
            <TableContainer>
              <thead>
                <tr key={"header"}>
                  {headerKeys.map((key) => (
                    <th>{replaceUnderscoresWithSpaces(key)}</th>
                  ))}
                </tr>
              </thead>

              <tbody>
                {array.map((item: any) => (
                  <tr key={item.id}>
                    {Object.values(item).map((val: any, index: number) => {
                      return index === 0 ? (
                        <td>{minifyWalletAddress(val)}</td>
                      ) : (
                        <td>{val}</td>
                      );
                    })}
                  </tr>
                ))}
              </tbody>
            </TableContainer>
          </PreviewCSV>
        ) : (
          <CsvPlaceholder>
            <div>Please choose CSV to see preview</div>
          </CsvPlaceholder>
        )}
        <ButtonContainer>
          <Button
            shade="secondary"
            onClick={(e: any) => {
              // @ts-ignore
              chooseCsvRef?.current?.click();
            }}
          >
            <span>Select CSV</span>
          </Button>
          <input
            ref={chooseCsvRef}
            type={"file"}
            id={"csvFileInput"}
            accept={".csv"}
            onChange={handleOnChange}
            hidden
          />
          <div>
            <Dropdown
              handleChange={handleChange}
              options={categoryTypes}
              value={selectedCategory}
            />
          </div>
          <Button
            isDisabled={!(csvContents && selectedCategory)}
            disabled={!(csvContents && selectedCategory)}
            onClick={(e: any) => {
              uploadCsv();
            }}
          >
            <span>Upload CSV</span>
          </Button>
        </ButtonContainer>
      </InputWrapper>
      <InputWrapper>
        <Heading>Whitelist user</Heading>
        <StyledInput
          placeholder="Enter user adddress"
          value={whitelistAddress}
          onChange={(e: any) => setWhitelistAddress(e.target.value)}
        />
        <ButtonContainer>
          <div>
            <Dropdown
              handleChange={setSelectedCategoryWhitelist}
              options={categoryTypes}
              value={selectedCategoryWhitelist}
            />
          </div>
          <Button
            isDisabled={!(whitelistAddress && selectedCategoryWhitelist)}
            disabled={!(whitelistAddress && selectedCategoryWhitelist)}
            onClick={async () => {
              try {
                setLoading(true);
                await toggleBlacklist(
                  whitelistAddress,
                  selectedCategoryWhitelist,
                  false
                );
                setLoading(false);
                setWhitelistAddress("");
                setSelectedCategoryWhitelist("");
              } catch (error) {
                console.log(error);
                setLoading(false);
                setWhitelistAddress("");
                setSelectedCategoryWhitelist("");
              }
            }}
            // onClick={() => {
            //   handleUserStatusChange(
            //     whitelistAddress,
            //     selectedCategoryWhitelist,
            //     userStatus.WHITELIST
            //   );
            // }}
          >
            <span>Whitelist</span>
          </Button>
        </ButtonContainer>
      </InputWrapper>
      <InputWrapper>
        <Heading>Blacklist user</Heading>
        <StyledInput
          placeholder="Enter user adddress"
          value={blacklistAddress}
          onChange={(e: any) => setBlacklistAddress(e.target.value)}
        />
        <ButtonContainer>
          <div>
            <Dropdown
              handleChange={setSelectedCategoryBlacklist}
              options={categoryTypes}
              value={selectedCategoryBlacklist}
            />
          </div>
          <Button
            isDisabled={!(blacklistAddress && selectedCategoryBlacklist)}
            disabled={!(blacklistAddress && selectedCategoryBlacklist)}
            onClick={async () => {
              try {
                setLoading(true);
                await toggleBlacklist(
                  blacklistAddress,
                  selectedCategoryBlacklist,
                  true
                );
                setLoading(false);
                setBlacklistAddress("");
                setSelectedCategoryBlacklist("");
              } catch (error: unknown) {
                console.log(error);
                setLoading(false);
                setBlacklistAddress("");
                setSelectedCategoryBlacklist("");
              }
            }}
            // onClick={() => {
            //   handleUserStatusChange(
            //     blacklistAddress,
            //     selectedCategoryBlacklist,
            //     userStatus.BLACKLIST
            //   );
            // }}
          >
            <span>Blacklist</span>
          </Button>
        </ButtonContainer>
      </InputWrapper>
      <InputWrapper>
        <Heading>Remove user</Heading>
        <StyledInput
          placeholder="Enter user adddress"
          value={removeAddress}
          onChange={(e: any) => setRemoveAddress(e.target.value)}
        />
        <ButtonContainer>
          <div>
            <Dropdown
              handleChange={setSelectedCategoryRemove}
              options={categoryTypes}
              value={selectedCategoryRemove}
            />
          </div>
          <Button
            isDisabled={!(removeAddress && selectedCategoryRemove)}
            disabled={!(removeAddress && selectedCategoryRemove)}
            onClick={async () => {
              try {
                setLoading(true);
                const res = await AdminService.removeVestingApi(
                  removeAddress,
                  selectedCategoryRemove
                );
                await removeVesting(
                  removeAddress,
                  selectedCategoryRemove,
                  res?.merkleRoot
                );

                setLoading(false);
                setRemoveAddress("");
                setSelectedCategoryRemove("");
              } catch (error) {
                console.log(error);

                setLoading(false);
                setRemoveAddress("");
                setSelectedCategoryRemove("");
              }
            }}
            // onClick={() => {
            //   handleUserStatusChange(
            //     removeAddress,
            //     selectedCategoryRemove,
            //     userStatus.REMOVE
            //   );
            // }}
          >
            <span>Remove</span>
          </Button>
        </ButtonContainer>
      </InputWrapper>
      <InputWrapper>
        {/* <Heading>Sync With Contract</Heading> */}

        <ButtonContainer>
          <div>
            <Dropdown
              handleChange={setSelectedCategoryToSync}
              options={categoryTypes}
              value={selectedCategoryToSync}
            />
          </div>
          <Button
            isDisabled={!selectedCategoryToSync}
            disabled={!selectedCategoryToSync}
            onClick={async () => {
              try {
                // selectedCategoryToSync

                setLoading(true);
                enqueueSnackbar("Transaction processing...", {
                  variant: "info",
                  persist: true,
                });
                await updateMerkleRoot(selectedCategoryToSync);
                setLoading(false);
                closeSnackbar();
                enqueueSnackbar("Synced successfully!", {
                  variant: "success",
                  persist: false,
                  autoHideDuration: 5000,
                });
                setSelectedCategoryToSync("");
              } catch (error) {
                console.log(error);
                setLoading(false);
                closeSnackbar();
                setSelectedCategoryToSync("");
              }
            }}
          >
            <span>Sync With Contract</span>
          </Button>
        </ButtonContainer>
      </InputWrapper>
    </Container>
  );
};
export default Manage;

import { useWeb3React } from "@web3-react/core";
import React, { useCallback, useEffect, useState } from "react";
import { useGetWalletBalance } from "../../hooks/useTokenBalance";
import useAuth from "../../walletHelpers/useAuth";
import { Button } from "../components/button";
import CustomModal from "../customModal";
import { colors } from "../styles/theme";
import {
  AddressInfo,
  AddressInfoWrap,
  BnbInfo,
  Content,
  WalletDetails,
} from "./style";
import { Metamask } from "../images";

const ConnectWallet = (props: any) => {
  const {
    connectWallet,
    setConnectWallet,
    showWalletContent,
    setIsAdminLoggedIn,
    setIsUserLoggedIn,
  } = props;
  const [disconnectWallet, setDisconnectWallet] = React.useState(false);
  const [walletType, setWalletType] = React.useState(false);

  const { logout } = useAuth();
  const { account, deactivate } = useWeb3React();

  const [accChangedCount, setAccChangedCount] = useState(0);

  const { balance } = useGetWalletBalance();

  const connect = useCallback(
    async (type?: any) => {
      if (account) {
        logout();
        deactivate();
        localStorage.clear();
        setDisconnectWallet(false);
        setIsUserLoggedIn(false);
        setIsAdminLoggedIn(false);
      }
    },
    [account, deactivate, logout, setIsAdminLoggedIn, setIsUserLoggedIn]
  );

  const logOutIfAdminAccChange = useCallback(() => {
    if (window.location.pathname.startsWith("/admin/")) {
      // let x = accChangedCount + 1;
      setAccChangedCount((prev) => {
        let x = prev + 1;
        if (x > 1) {
          logout();
        }
        return prev + 1;
      });
    }
  }, [logout]);

  useEffect(() => {
    //@ts-ignore
    let walletType = JSON.parse(localStorage.getItem("connectorId"));
    //@ts-ignore
    const walletconnect = JSON.parse(localStorage.getItem("walletconnect"));

    if (account) {
      setConnectWallet(true);
      localStorage.setItem("address", JSON.stringify(account));
      localStorage.setItem("walletConnected", JSON.stringify(true));
      logOutIfAdminAccChange();
    } else if (walletType === 2 && walletconnect === null) {
      logout();
      localStorage.clear();
    }
  }, [account, logout, logOutIfAdminAccChange]);

  useEffect(() => {
    //@ts-ignore
    const walletType = JSON.parse(localStorage.getItem("connectorId"));
    setWalletType(walletType);
  }, []);

  return (
    <>
      <Content>
        {showWalletContent ? (
          connectWallet && account ? (
            <AddressInfoWrap menu={props.menu}>
              <BnbInfo menu={props.menu}>
                {" "}
                {Number(balance.toString()).toFixed(2)} MATIC
              </BnbInfo>
              <Button
                onClick={() => {
                  setDisconnectWallet(true);
                  props.showLogout(true);
                }}
              >
                <div className="btnBody">
                  <div className="walletIconCont">
                    <img src={Metamask} alt="down caret" className="caret" />
                  </div>{" "}
                  {account?.slice(0, 6)}...{account?.slice(-4)}
                </div>
              </Button>
              {/* <AddressInfo
                onClick={() => {
                  setDisconnectWallet(true);
                  props.showLogout(true);
                }}
              >
                {account}
              </AddressInfo> */}
            </AddressInfoWrap>
          ) : null
        ) : null}
        {localStorage.getItem("address") && (
          <CustomModal
            show={disconnectWallet}
            toggleModal={() => {
              setDisconnectWallet(false);
              props.showLogout(false);
            }}
            heading="Wallet"
            // headIcon=""
          >
            <WalletDetails>
              <p>
                {account?.slice(0, 15)}...{account?.slice(-15)}
              </p>
              <div className="disconnectBtnCont">
                <Button
                  color={colors.black}
                  onClick={() => connect(walletType)}
                >
                  Disconnect
                </Button>
              </div>
            </WalletDetails>
          </CustomModal>
        )}
      </Content>
    </>
  );
};
export default ConnectWallet;

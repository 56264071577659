import React from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Area,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  Label,
} from "recharts";
import { Container } from "./style";
import BigNumber from "bignumber.js";
import { formatNumber } from "../../../../utils/helpers";

// ChartJS.register(
//   CategoryScale,
//   LinearScale,
//   PointElement,
//   LineElement,
//   Title,
//   //   Tooltip,
//   Filler,
//   Legend
// );

const maxSize = 48;

let chartData: any = {
  "Private Sale": {
    data: [],
    borderColor: "rgb(235, 53, 168)",
    backgroundColor: "rgba(235, 53, 220, 0.215)",
  },
  Marketing: {
    data: [],
    borderColor: "rgb(235, 159, 53)",
    backgroundColor: "rgba(235, 144, 53, 0.267)",
  },
  Team: {
    data: [],
    borderColor: "rgb(235, 226, 53)",
    backgroundColor: "rgba(235, 226, 53, 0.218)",
  },
  "Play & Earn": {
    data: [],
    borderColor: "rgb(135, 235, 53)",
    backgroundColor: "rgba(80, 235, 53, 0.294)",
  },
  Staking: {
    data: [],
    borderColor: "rgb(53, 235, 156)",
    backgroundColor: "rgba(53, 235, 184, 0.241)",
  },
  Advisors: {
    data: [],
    borderColor: "rgb(53, 162, 235)",
    backgroundColor: "rgba(53, 162, 235, 0.262)",
  },
  IEO: {
    data: [],
    borderColor: "rgb(114, 53, 235)",
    backgroundColor: "rgba(53, 56, 235, 0.29)",
  },
};

const xAxisLabels = [
  "Month 0",
  "Month 1",
  "Month 2",
  "Month 3",
  "Month 4",
  "Month 5",
  "Month 6",
  "Month 7",
  "Month 8",
  "Month 9",
  "Month 10",
  "Month 11",
  "Month 12",
  "Month 13",
  "Month 14",
  "Month 15",
  "Month 16",
  "Month 17",
  "Month 18",
  "Month 19",
  "Month 20",
  "Month 21",
  "Month 22",
  "Month 23",
  "Month 24",
  "Month 25",
  "Month 26",
  "Month 27",
  "Month 28",
  "Month 29",
  "Month 30",
  "Month 31",
  "Month 32",
  "Month 33",
  "Month 34",
  "Month 35",
  "Month 36",
  "Month 37",
  "Month 38",
  "Month 39",
  "Month 40",
  "Month 41",
  "Month 42",
  "Month 43",
  "Month 44",
  "Month 45",
  "Month 46",
  "Month 47",
  "Month 48",
];

const categories = [
  "Private Sale",
  "Marketing",
  "Team",
  "Play & Earn",
  "Staking",
  "Advisors",
  "IEO",
].reverse();

for (let i = 0; i <= maxSize; i++) {
  chartData["Private Sale"].data.push(i <= 12 ? 440000 * i : 440000 * 12);
  chartData["Marketing"].data.push(i <= 12 ? 400000 * i : 400000 * 12);
  chartData["Team"].data.push(i <= 24 ? 300000 * i : 300000 * 24);
  chartData["Play & Earn"].data.push(i <= 48 ? 220000 * i : 220000 * 48);
  chartData["Staking"].data.push(i <= 48 ? 220000 * i : 220000 * 48);
  chartData["Advisors"].data.push(i <= 24 ? 160000 * i : 160000 * 24);
  chartData["IEO"].data.push(i <= 12 ? 80000 * i : 80000 * 12);
}

// const getNumberInMillions = function (num: number) {
//   let units = ["M", "B", "T", "Q"];
//   let unit = Math.floor((num / 1.0e1).toFixed(0).toString().length);
//   let r = unit % 3;
//   let x =
//     Math.abs(Number(num)) / parseFloat(Number("1.0e+" + (unit - r)).toFixed(2));
//   if (x === 0) {
//     return 0;
//   }
//   return x + " " + units[Math.floor(unit / 3) - 2];
// };

// export const options: any = {
//   responsive: true,
//   maintainAspectRatio: false,
//   onResize: (chart: any, newSize: any) => {
//     if (newSize.width < 600) {
//       chart.options.plugins.legend = false;
//       chart.update();
//     } else {
//       chart.options.plugins.legend = true;
//       chart.update();
//     }
//   },
//   interaction: {
//     intersect: false,
//     mode: "index",
//   },
//   plugins: {
//     legend: {
//       position: "top" as const,
//     },
//     title: {
//       display: true,
//       text: "Monthly Token Distribution of All Teams",
//     },
//     // tooltip: {
//     //   enabled: true,
//     //   position: "nearest",
//     // },
//   },
//   scales: {
//     x: {
//       title: {
//         display: true,
//         text: "Month",
//       },
//     },
//     y: {
//       drawOnChartArea: true,
//       stacked: false,
//       ticks: {
//         callback: (label: any) => `${getNumberInMillions(label)}`,
//       },
//       title: {
//         display: true,
//         text: "Total Released Tokens till date (category wise)",
//       },
//     },
//   },
// };

export const data: any = {
  labels: xAxisLabels,
  datasets: categories.map((category) => ({
    fill: true,
    label: category,
    data: chartData[category]?.data,
    borderColor: chartData[category].borderColor,
    backgroundColor: chartData[category].backgroundColor,
  })),
};

export const data2: any = xAxisLabels?.map((xAxisLabel, i) => ({
  month: xAxisLabel,
  [`${categories[0]}`]: chartData[categories[0]]?.data?.[i],
  [`${categories[1]}`]: chartData[categories[1]]?.data?.[i],
  [`${categories[2]}`]: chartData[categories[2]]?.data?.[i],
  [`${categories[3]}`]: chartData[categories[3]]?.data?.[i],
  [`${categories[4]}`]: chartData[categories[4]]?.data?.[i],
  [`${categories[5]}`]: chartData[categories[5]]?.data?.[i],
  [`${categories[6]}`]: chartData[categories[6]]?.data?.[i],
}));

function TokenDistributionChart2() {
  return (
    <ResponsiveContainer>
      <AreaChart
        margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
        barCategoryGap={1}
        data={data2}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          tickLine={false}
          axisLine={false}
          interval="preserveEnd"
          minTickGap={120}
          tickFormatter={(tick) => tick}
          dataKey="month"
          tick={{ fill: "grey", fontSize: 11 }}
          tickMargin={8}
        ></XAxis>
        <YAxis
          //   dataKey="amt"
          type="number"
          tickFormatter={(tick) =>
            tick ? formatNumber(new BigNumber(tick)?.toNumber()) : "$0"
          }
          axisLine={false}
          tickLine={false}
          interval="preserveEnd"
          tick={{ fill: "grey", fontSize: 11 }}
        />
        <Tooltip
          contentStyle={{
            background: "#020106",
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: "4px",
            color: "#fff",
          }}
          // content={<CustomTooltip />}
          formatter={(val, key) => [
            val ? formatNumber(new BigNumber(val as number).toNumber()) : "0",
            key,
          ]}
          labelFormatter={(label) => label}
        />
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgba(101, 20, 160, 0.5)" />
            <stop offset="100%" stopColor="rgba(101, 20, 160, 0.05)" />
          </linearGradient>
          <linearGradient id="colorPv1" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#B33A3A" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#B33A3A" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#00D7E7" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#00D7E7" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv3" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#32AF34" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#32AF34" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv4" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#FBEC5B" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#FBEC5B" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv5" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#0346F2" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#0346F2" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv6" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#fbb226" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#fbb226" stopOpacity={0} />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey={`${categories[0]}`}
          strokeWidth="2"
          stroke="rgba(101, 20, 160, 1)"
          fill="url(#gradient)"
        />

        <Area
          type="monotone"
          dataKey={`${categories[1]}`}
          strokeWidth="2"
          stroke="#B33A3A"
          fill="url(#colorPv1)"
        />
        <Area
          type="monotone"
          dataKey={`${categories[2]}`}
          strokeWidth="2"
          stroke="#00D7E7"
          fill="url(#colorPv2)"
        />

        <Area
          type="monotone"
          dataKey={`${categories[3]}`}
          strokeWidth="2"
          stroke="#32AF34"
          fill="url(#colorPv3)"
        />

        <Area
          type="monotone"
          dataKey={`${categories[4]}`}
          strokeWidth="2"
          stroke="#FBEC5B"
          fill="url(#colorPv4)"
        />
        <Area
          type="monotone"
          dataKey={`${categories[5]}`}
          strokeWidth="2"
          stroke="#0346F2"
          fill="url(#colorPv5)"
        />
        <Area
          type="monotone"
          dataKey={`${categories[6]}`}
          strokeWidth="2"
          stroke="#fbb226"
          fill="url(#colorPv6)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default TokenDistributionChart2;

import * as React from "react";
import { Fragment } from "react";
import { Loading } from "../../images";
import { LoaderCont } from "./style";
// import loading from '../../assets/image/load.gif'

const Loader = ({
  width = "60px",
  display,
}: {
  width?: string;
  display: boolean;
}) => (
  <LoaderCont display={display}>
    <img src={Loading} style={{ width, margin: "auto" }} alt="Loading..." />
  </LoaderCont>
);

export default Loader;

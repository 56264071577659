import { closeSnackbar, enqueueSnackbar } from "notistack";
import api from "./api";

const uploadCsv = async (csvFile: any, category: any) => {
  try {
    enqueueSnackbar("Uploading CSV. Please wait...", {
      variant: "default",
      persist: true,
    });
    let formdata = new FormData();
    formdata.append("file", csvFile);
    const res = await api.post(`/admin/upload/${category}`, formdata);
    closeSnackbar();
    enqueueSnackbar("CSV upload successful!", {
      variant: "success",
      persist: false,
      autoHideDuration: 5000,
    });
    return res?.data;
    // return api.post(`/admin/upload/${category}`, formdata).then((response) => {
    //   return response.data;
    // });
  } catch (error: unknown) {
    const err = error as {
      response: { data: { error: string; statusCode: number } };
    };
    console.log(error, err?.response);
    const errMsg = err?.response?.data?.error;
    closeSnackbar();
    enqueueSnackbar(errMsg ? errMsg : "CSV upload Failed. Please try again.", {
      variant: "error",
      persist: false,
      hideIconVariant: true,
      autoHideDuration: 5000,
    });
    throw err;
  }
};

const UploadService = {
  uploadCsv,
};

export default UploadService;

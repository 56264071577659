import styled from "styled-components";
import { colors, screenSizes } from "../../../../shared/styles/theme";

export const TabContainer = styled.div`
  display: flex;
  /* margin: 10px; */
  gap: 24px;
  margin-bottom: 2rem;
  /* box-shadow: rgb(204, 219, 232) -10px 0px 20px 2px inset,
    rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset; */
  /* border-radius: 50px; */
`;
export const TabItem = styled.div<any>`
  /* flex: 50%; */
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.875rem;
  font-weight: 600;
  color: ${(props) => (props.isActive ? colors.white : "#A7A9AC")};
  cursor: pointer;
  background-color: ${(props) => (props.isActive ? "#2fe096" : "#636363")};
  /* background-color: ${(props) =>
    props.isActive ? colors.primaryBlue : null}; */
  border: none;
  /* font-family: SFProText-Regular, Helvetica, Arial, sans-serif; */
  font-size: 1.375rem;
  padding: 0.625rem;
  transition: 0.3s;
  cursor: pointer;
  text-align: center;
  border-radius: 8px;
`;
export const CardContainer = styled.div`
  border-radius: 4px;
`;

export const Container = styled.div`
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  border-radius: 10px;
  min-width: 0;
  .actionbtnContainer {
    align-self: center;
    margin-top: 1.5rem;
  }
  @media (max-width: ${screenSizes.mediaM}px) {
    width: 100%;
  }
`;

export const TokenDetailsWrapper = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
  }
  .title {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 1.5rem;
  }
  .value {
    font-size: 1.875rem;
    color: ${colors.white};
  }
`;

export const TokenItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
  justify-content: space-between;
  span {
    margin-left: 6px;
    margin-right: auto;
    font-weight: 600;
    line-height: 24px;
  }
`;

export const TokenIconContainer = styled.div`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  gap: 5px;
  /* margin-bottom: 1rem; */
`;

export const TokenIcon = styled.img`
  width: 24px;
  cursor: pointer;
`;

export const TokenBalance = styled.div`
  font-weight: 600;
  line-height: 24px;
  color: ${colors.grey};
  text-align: center;
`;

export const WarningText = styled.div`
  font-weight: 600;
  line-height: 16px;
  color: ${colors.red};
  text-align: center;
  margin: 1rem 0;
`;

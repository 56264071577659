import styled from "styled-components";

export const LoaderCont = styled.div<{ display: boolean }>`
  position: fixed;
  inset: 0;

  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  display: ${(props) => (props?.display ? "flex" : "none")};
`;

import { closeSnackbar, enqueueSnackbar } from "notistack";
import api from "./api";
import TokenService from "./token.service";

const getMerkleRoot = (category: string) => {
  return api.get(`/admin/merkleRoot/${category}`).then((response) => {
    return response?.data;
  });
};

const removeVestingApi = async (userAddress: string, team: string) => {
  try {
    enqueueSnackbar("Removal Started.Please wait...", {
      variant: "default",
      persist: true,
    });
    const res = await api.post(
      `/admin/removeVesting?address=${userAddress}&category=${team}`
    );
    closeSnackbar();
    if (res.data.accessToken) {
      TokenService.updateNewAccessTokens(res.data);
    }
    enqueueSnackbar("Removed from Database", {
      variant: "success",
      persist: false,
      autoHideDuration: 5000,
    });
    return res?.data;
  } catch (error: unknown) {
    const err = error as {
      response: { data: { error: string; statusCode: number } };
    };
    console.log(error, err?.response);
    const errMsg = err?.response?.data?.error;
    closeSnackbar();
    enqueueSnackbar(
      errMsg ? errMsg : "Something went wrong. Please try again.",
      {
        variant: "error",
        persist: false,
        hideIconVariant: true,
        autoHideDuration: 5000,
      }
    );
    throw err;
  }
};

const AdminService = {
  getMerkleRoot,
  removeVestingApi,
};

export default AdminService;

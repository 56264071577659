import styled, { keyframes } from "styled-components";
import { colors, screenSizes } from "../styles/theme";

export const Content = styled.div``;
export const SemiHead = styled.p`
  font-size: 12px;
  line-height: 19px;
  font-family: Light;
  margin: 0;
  color: white;
  @media (min-width: ${screenSizes.mediaM}px) {
    font-size: 16px;
  }
`;
export const AddressInfoWrap = styled.div<{ menu: any }>`
  display: flex;
  justify-content: center;
  .btnBody {
    display: flex;
    gap: 8px;
    align-items: center;
    /* .walletIconCont {
      width: 1.5rem;
    } */
  }
  @media (max-width: ${screenSizes.mediaS}px) {
    /* transform: translateX(-125px); */
  }
  @media (min-width: ${screenSizes.mediaS}px) {
    /* transform: ${(props) =>
      props.menu ? "translateX(-107%)" : "inherit"}; */
  }
`;
export const ConnectButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  border: 1px solid rgba(186, 169, 255, 0.25);
  text-transform: uppercase;
  padding: 10px;
  transition: all 0.2s;
  img {
    margin-right: 8px;
  }
  p {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
    margin: 0;
    color: ${colors.neon};
    margin-top: 2px;
  }
  &:hover {
    background: rgba(186, 169, 255, 0.1);
  }
  @media (max-width: ${screenSizes.mediaS}px) {
    transform: translateX(-134px);
  }
`;
export const BnbInfo = styled.div<{ menu: any }>`
  background: transparent;
  /* color: ${colors.neon}; */
  font-size: 1.375rem;
  line-height: 19px;
  align-self: center;
  margin-right: 1rem;
  /* font-family: SemiBold; */

  /* border: 0.5px solid ${(props) => props.theme.secondary}; */
  /* padding: 12px 50px 9px 25px; */
  /* height: 23px; */
  display: none;

  @media (max-width: 414px) {
    /* width: 101px; */
  }
  @media (min-width: ${screenSizes.mediaS}px) {
    display: block;
    /* font-size: 12px;
    padding: 12px 50px 9px 15px;
    width: ${(props) => (props.menu ? "80px" : "inherit")}; */
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    /* font-size: 16px;
    padding: 12px 50px 9px 25px; */
  }
`;

export const AddressInfo = styled.div`
  background-color: ${colors.primaryBlue};
  color: ${colors.white};
  font-size: 12px;
  /* line-height: 19px; */
  /* font-family: SemiBold; */
  /* border: 0.5px solid ${(props) => props.theme.secondary}; */
  border-radius: 10px;
  /* padding: 12px 25px 7px 25px; */
  height: 45px;
  /* margin-left: -40px; */
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  @media (min-width: ${screenSizes.mediaS}px) {
    font-size: 12px;
    /* padding: 12px 10px 7px 10px; */
    width: 80px;
  }
  @media (min-width: ${screenSizes.mediaL}px) {
    font-size: 16px;
    /* padding: 12px 25px 7px 25px; */
    width: 120px;
  }
`;

export const OptionArea = styled.div`
  border-radius: 0px;
  margin-bottom: 25px;
`;

export const WalletOption = styled.div`
  background: ${colors.base};
  border-radius: 0px;
  background: rgba(186, 169, 255, 0.07);
  border: 1px solid rgba(186, 169, 255, 0.1);
  margin-bottom: 15px;
  z-index: 0;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 16px;
  &:hover {
    // transform: translate(3px);
    // border: 1px solid #615d71;
  }
  &:active {
    transform: translate(1px, -1px);
  }

  p {
    margin: 0;
    font-weight: 600;
    font-size: 20px;
    line-height: 25px;
    font-family: SemiBold;
    color: white;
    margin-top: 15px;
  }
`;

export const WalletDetails = styled.div`
  margin-top: 1.5rem;
  /* margin-bottom: 15px; */
  p {
    font-size: 2rem;
    font-weight: 700;
    /* line-height: 23px; */
    /* font-family: Light; */
    /* margin-top: 15px; */
    /* text-align: center; */
    word-break: break-all;
  }
  .disconnectBtnCont {
    margin-top: 2.25rem;
  }
`;

export const LinksFlex = styled.div`
  display: flex;
  justify-content: space-around;
  margin: 0 0 60px 0;
  a {
    color: #fbec5b;
    text-decoration: none;
    font-size: 12px;
    line-height: 19px;
    font-family: Light;
    letter-spacing: 0.3px;
    cursor: pointer;
    @media (min-width: ${screenSizes.mediaM}px) {
      font-size: 16px;
    }
  }
  img {
    height: 20px;
    margin-left: 5px;
    display: inline-block;
    margin-top: -3px;
    cursor: pointer;
    @media (min-width: ${screenSizes.mediaM}px) {
      height: 24px;
    }
  }
  p {
    color: ${colors.white};
    font-size: 12px;
    font-family: Light;
    margin: 0;
  }
`;
const breatheAnimation = keyframes`
 0% {  transform: translateY(0px);}
 25% {  transform: translateY(15px);}
 50% {  transform: translateY(10px);}
 75% { transform: translateY(5px); }
 100% { transform: translateY(0px); }

 `;
export const StatusImage = styled.img`
  animation: ${breatheAnimation} 1.5s linear infinite;
`;
export const StatusContent = styled.div`
  text-align: center;
  a {
    font-family: Light;
    font-size: 16px;
    line-height: 19px;
    color: #fbec5b;
    margin-right: 5px;
    margin-top: 4px;
  }
  p {
    font-family: SemiBold;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    margin: 0;
    color: ${colors.white};
  }
  h2 {
    font-family: Medium;
    font-size: 16px;
    line-height: 19px;
    margin: 10px 0;
    color: ${colors.white};
  }

  h3 {
    font-family: Light;
    font-size: 14px;
    line-height: 16px;
    margin: 0;
    color: ${colors.white};
    font-weight: 300;
  }
`;

export const LinkFlex = styled.div`
  display: flex;

  img {
    margin-top: -4px;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
`;
export const LogoutButton = styled.button`
  align-items: center;
  background-image: linear-gradient(135deg, #f34079 40%, #fc894d);
  border: 0;
  border-radius: 10px;
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-family: "Codec cold", sans-serif;
  font-size: 14px;
  font-weight: 700;
  height: 48px;
  justify-content: center;
  letter-spacing: 0.4px;
  line-height: 1;
  max-width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 3px;
  text-decoration: none;
  text-transform: uppercase;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;

  &:active {
    outline: 0;
  }

  &:hover {
    outline: 0;
  }

  pan {
    transition: all 200ms;
  }

  &&:hover span {
    transform: scale(0.9);
    opacity: 0.75;
  }

  @media screen and (max-width: 991px) {
    font-size: 15px;
    height: 50px;
  }

  span {
    line-height: 50px;
  }
`;

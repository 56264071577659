import React from "react";
import { CustomDropdown } from "./style";
import { DownCaret } from "../../images";

function Dropdown(props: any) {
  const { options, handleChange, value, showEmptyState = true } = props;
  const handleOptionSelect = (e: any) => {
    handleChange(e.target.value);
  };

  return (
    <CustomDropdown>
      <select onChange={handleOptionSelect} value={value}>
        {showEmptyState && <option value="">Select category</option>}
        {options.map((item: any, index: number) => (
          <option key={item} value={item}>
            {item}
          </option>
        ))}
      </select>
      <img src={DownCaret} alt="down caret" className="caret" />
    </CustomDropdown>
  );
}

export default Dropdown;

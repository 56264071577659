// import { formatNumber } from '@/utilities/helpers'

import React from "react";
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  ResponsiveContainer,
  TooltipProps,
  Label,
} from "recharts";
import { useTheme } from "styled-components";
import { formatNumber } from "../../../../utils/helpers";
import BigNumber from "bignumber.js";

const format = "DD MMM";

// const data = [
//   { date: dayjs(1681970520683).format(format), value: 200 },
//   { date: dayjs(1681981320683).format(format), value: 300 },
//   { date: dayjs(1681977720683).format(format), value: 100 },
//   { date: dayjs(1681974120683).format(format), value: 200 },
//   { date: dayjs(1681970520683).format(format), value: 100 },
// ]

// const gradientOffset = () => {
//   const dataMax = Math.max(...data.map(i => i.value))
//   const dataMin = Math.min(...data.map(i => i.value))

//   if (dataMax <= 0) {
//     return 0
//   }
//   if (dataMin >= 0) {
//     return 1
//   }

//   return dataMax / (dataMax - dataMin)
// }

// const CustomTooltip = ({ active, payload, label }:any) => {
//   if (active && payload && payload.length) {
//     return (
//       <div className="custom-tooltip">
//         <p className="label">{`${label}: ${payload[0].value}`}</p>
//       </div>
//     );
//   }

//   return null;
// };

const TokenDistributionChart2 = ({
  data,
}: {
  data: { value: number; date: string }[] | undefined;
}) => {
  //   const theme = useTheme();
  return (
    <ResponsiveContainer
    // aspect={60 / 20}
    //   height={400}
    >
      <AreaChart
        // width={600}
        // height={400}
        margin={{ top: 20, right: 30, left: 0, bottom: 10 }}
        barCategoryGap={1}
        data={data}
      >
        {/* <CartesianGrid strokeDasharray="3 3" /> */}
        <XAxis
          tickLine={false}
          axisLine={false}
          interval="preserveEnd"
          // tickMargin={16}
          minTickGap={120}
          tickFormatter={(tick) => tick}
          dataKey="date"
          tick={{ fill: "grey", fontSize: 11 }}
          tickMargin={8}
          // type={'number'}
          // domain={domain}
        >
          {/* <Label
            value="Monthly token distribution"
            offset={-10}
            position="insideBottom"
          /> */}
        </XAxis>
        <YAxis
          dataKey="value"
          type="number"
          // orientation="right"
          // tickFormatter={(tick, i) => {
          //   if (tick === 0) return ''
          //   return tick ? '$' + formatNumber(tick) : '$0'
          // }}
          tickFormatter={(tick) =>
            tick ? formatNumber(new BigNumber(tick)?.toNumber()) : "$0"
          }
          axisLine={false}
          tickLine={false}
          interval="preserveEnd"
          // minTickGap={80}
          // yAxisId={0}
          tick={{ fill: "grey", fontSize: 11 }}
        />
        <Tooltip
          contentStyle={{
            background: "#020106",
            border: "1px solid rgba(255, 255, 255, 0.5)",
            borderRadius: "4px",
            color: "#fff",
          }}
          // content={<CustomTooltip />}
          formatter={(val) => [
            val ? formatNumber(val as number) : "0",
            "Value",
          ]}
          labelFormatter={(label) => label}
        />
        <defs>
          <linearGradient id="gradient" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="rgba(101, 20, 160, 0.5)" />
            <stop offset="100%" stopColor="rgba(101, 20, 160, 0.05)" />
          </linearGradient>
        </defs>
        <Area
          type="monotone"
          dataKey="value"
          strokeWidth="2"
          stroke="rgba(101, 20, 160, 1)"
          fill="url(#gradient)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default TokenDistributionChart2;
